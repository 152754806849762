import React from 'react'
import styled, { css } from 'styled-components/macro'

import { Swiper, SwiperSlide, SwiperProps } from 'swiper/react'
import { PaginationOptions, SwiperEvents, SwiperOptions } from 'swiper/types'
import { ReactComponent as LeftArrow } from 'src/assets/images/arrowLeft_icon.svg'

import { ReactComponent as RightArrow } from 'src/assets/images/arrowRight_icon.svg'

import 'swiper/css'
import 'swiper/css/effect-fade'

type TSliderEvents = {
  onInit?: SwiperEvents['init']
  onSlideChangeTransitionStart?: SwiperEvents['slideChangeTransitionStart']
}

export type TSliderSettings = SwiperOptions & TSliderEvents

type Props = {
  className?: string
  settings?: TSliderSettings
  children?: React.ReactNode
} & SwiperProps

// swiper
// https://swiperjs.com/swiper-api
// types
// https://swiperjs.com/types/modules
export const Slider = ({ className, settings, children, ...restProps }: Props) => {
  if (settings?.pagination) {
    ;(settings?.pagination as PaginationOptions).el = cssSelectors.Pagination
  }

  return (
    <Swiper
      className={className}
      {...settings}
      followFinger
      navigation={{ prevEl: cssSelectors.NavigationPrev, nextEl: cssSelectors.NavigationNext }}
      {...restProps}
    >
      {React.Children.map(children, (child) => (
        <SwiperSlide>{child}</SwiperSlide>
      ))}
      {settings?.pagination && <Pagination className={cssSelectors.Pagination.replace('.', '')} />}
      {settings?.navigation && (
        <>
          <StyledLeftArrow className={cssSelectors.NavigationPrev.replace('.', '')} />
          <StyledRightArrow className={cssSelectors.NavigationNext.replace('.', '')} />
        </>
      )}
    </Swiper>
  )
}

export const cssSelectors = {
  Swiper: '.swiper',
  Pagination: '.swiper-pagination',
  PaginationBullet: '.swiper-pagination-bullet',
  PaginationBulletActive: '.swiper-pagination-bullet-active',
  NavigationPrev: '.swiper-button-prev',
  NavigationNext: '.swiper-button-next',
  NavigationDisabled: '.swiper-button-disabled',
}

const ArrowCss = css`
  cursor: pointer;
  position: absolute;
  z-index: 2;
  top: 70px;
  ${({ theme }) => theme.media.isMobile} {
    top: 78px;
  }
`

const StyledLeftArrow = styled(LeftArrow)`
  ${ArrowCss}
  left: 8px;
`

const StyledRightArrow = styled(RightArrow)`
  ${ArrowCss}
  right: 8px;
`

const Pagination = styled.div`
  justify-content: center;
  display: flex;
  margin-top: 16px;

  ${cssSelectors.PaginationBullet} {
    width: 8px;
    height: 8px;
    background: grey;
    border-radius: 14px;
    margin: 0 4px;
    cursor: pointer;
  }

  ${cssSelectors.PaginationBulletActive} {
    width: 24px;
    background: #e0e0e0;
  }
`
