import { TReduxState } from 'src/redux/store'

export const getProduct = (state: TReduxState) => state.product.data
export const getIsProductResolved = (state: TReduxState) => state.product.resolved.fetchProduct

export const getProductStats = (state: TReduxState) => state.product.stats
export const getIsFetchProductStatsLoading = (state: TReduxState) => state.product.loading.fetchCampaignStats

export const getProductCurrentCampaign = (state: TReduxState) => state.product.data?.currentCampaign
export const getGameResult = (state: TReduxState) => state.product.gameResult
export const getProductUserConfig = (state: TReduxState) => state.product.data?.currentUser
export const getCurrentCampaignId = (state: TReduxState) => state.product.data?.currentCampaign?.id
export const getSymbol = (symbolId: number) => (state: TReduxState) =>
  state.product.data?.currentCampaign.symbols.find((item) => item.id === symbolId)

export const getCurrentCampaignRules = (state: TReduxState) => state.product.data?.currentCampaign?.rules

export const getIsPlayGameResolved = (state: TReduxState) => state.product.resolved.playGame

export const getIsPlayGameLoading = (state: TReduxState) => state.product.loading.playGame
