import styled from 'styled-components/macro'

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  ${({ theme }) => theme.media.isMobile} {
    flex: 1 1 auto;
    padding-right: 12px;
  }
`
