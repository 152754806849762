import styled from 'styled-components/macro'

import { PlayState } from 'src/enums/slotMachine'

type Props = {
  playState: PlayState | null
  userNickName: string | undefined
  userEmail: string | undefined
  title: string | undefined
}

export const Description = ({ playState, userNickName, userEmail, title }: Props) => {
  if (playState === PlayState.DidWin) {
    return (
      <>
        <DescriptionWrap>
          Wahnsinn, {userNickName}
          <br /> Du hast gewonnen!
        </DescriptionWrap>
        <DescriptionSub>Du erhältst gleich eine E-Mail an {userEmail} mit Infos zu deinem Gewinn!</DescriptionSub>
      </>
    )
  }

  if (playState === PlayState.NoPlaysLeft) {
    return (
      <DescriptionWrap>
        Schade, {userNickName}.
        <br /> Versuch dein Glück morgen wieder!
      </DescriptionWrap>
    )
  }

  if (playState === PlayState.NoPlaysLeftInCampaign) {
    return <DescriptionWrap>Schade, doch das nächste Gewinnspiel folgt bald.</DescriptionWrap>
  }

  if (playState === PlayState.OnePlayLeft) {
    return (
      <DescriptionWrap>
        Knapp daneben. Einen
        <br /> Versuch hast du noch.
      </DescriptionWrap>
    )
  }

  if (playState === PlayState.PlayAgain) {
    return (
      <DescriptionWrap>
        Das war leider nichts.
        <br /> Versuchs gleich nochmal.
      </DescriptionWrap>
    )
  }

  if (playState === PlayState.NoGame) {
    return <DescriptionWrap>Zur Zeit läuft kein Spiel.</DescriptionWrap>
  }

  if (playState === PlayState.АlreadyWonOnce) {
    return (
      <DescriptionWrap>
        Du kannst pro Spiel nur
        <br /> ein Mal gewinnen
      </DescriptionWrap>
    )
  }

  return <DescriptionWrap>{title}</DescriptionWrap>
}

const DescriptionWrap = styled.span`
  margin: 0 auto;
  text-align: center;
  max-width: 654px;
  display: block;
  margin-top: 10px;
  color: ${({ theme }) => theme.colors.blick.white};
  font-family: ${({ theme }) => theme.fonts.goodOffcPro};
  font-size: 42px;
  line-height: 50px;
  font-weight: 900;
  letter-spacing: 0.02em;
  ${({ theme }) => theme.media.isMobile} {
    transform: translateX(8px);
    margin-top: 4px;
    font-size: 28px;
    line-height: 36px;
  }
`

const DescriptionSub = styled.span`
  display: block;
  margin: 0 auto;
  margin-top: 16px;
  max-width: 654px;
  text-align: center;
  color: ${({ theme }) => theme.colors.blick.white};
  font-size: 22px;
  line-height: 25px;
  font-weight: 600;
  word-break: break-word;
  ${({ theme }) => theme.media.isMobile} {
    padding-left: 16px;
  }
`
