import { legacy_createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'

import { rootReducer } from './rootReducer'
import { composeWithDevTools } from '@redux-devtools/extension'
import { trackingMiddleware } from 'src/tracking/gtm/trackingMiddleware'

// setup middlewares
const enhancers = composeWithDevTools(applyMiddleware(thunk, trackingMiddleware))

export const store = legacy_createStore(rootReducer, enhancers)

Object.assign(window, { __STORE: store })

export type TReduxState = ReturnType<typeof store.getState>
export type TAppDispatch = typeof store.dispatch

export const setupStore = () => ({ store })
