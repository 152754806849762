import { combineReducers } from 'redux'

import { reducer as prizesReducer } from 'src/api/prizes/reducer'
import { reducer as auth } from 'src/api/auth/reducer'
import { reducer as productReducer } from 'src/api/product/reducer'
import { reducer as statsReducer } from 'src/api/stats/reducer'
import { reducer as appReducer } from 'src/state/app/reducer'
import { reducer as marketingReducer } from 'src/api/marketing/reducer'
import { reducer as feedbackReducer } from 'src/api/feedback/reducer'

export const rootReducer = combineReducers({
  auth,
  product: productReducer,
  prizes: prizesReducer,
  stats: statsReducer,
  app: appReducer,
  marketing: marketingReducer,
  feedback: feedbackReducer,
})
