import { Reducer } from 'redux'

import { TActions, TPrizesState } from './types'
import { ActionTypes } from './enums'

import { getReduxFailureStates, getReduxFetchStates, getReduxSuccessStates } from 'src/utils/redux'

export const initialState: TPrizesState = {
  data: null,
  forceWinPrizeId: '-1',
  loading: {
    fetchPrizes: false,
  },
  error: {
    fetchPrizes: null,
  },
  resolved: {
    fetchPrizes: false,
  },
}

export const reducer: Reducer<TPrizesState, TActions> = (state = initialState, action): TPrizesState => {
  switch (action.type) {
    case ActionTypes.FETCH_PRIZES: {
      return {
        ...state,
        ...getReduxFetchStates(state, 'fetchPrizes'),
      }
    }
    case ActionTypes.FETCH_PRIZES_SUCCESS: {
      return {
        ...state,
        data: action.payload,
        ...getReduxSuccessStates(state, 'fetchPrizes'),
      }
    }
    case ActionTypes.FETCH_PRIZES_FAILURE: {
      return {
        ...state,
        ...getReduxFailureStates(state, 'fetchPrizes', action.payload),
      }
    }
    case ActionTypes.SET_FORCE_WIN_PRIZE_ID: {
      return {
        ...state,
        forceWinPrizeId: action.payload,
      }
    }
    case ActionTypes.CLEAR_FORCE_WIN_PRIZE_ID: {
      return {
        ...state,
        forceWinPrizeId: '-1',
      }
    }
    default:
      return state
  }
}
