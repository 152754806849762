// Equally parition an array into multiple, equally sized, partitions.
// This is the opposite of flatten.
// Returns the splice breakpoints for the input array.
export const calculateSpread = <T>(array: Array<T>, partitionCount: number): number[] => {
  const linkCount = array.length
  const itemsPerPartition = Math.floor(linkCount / partitionCount)
  const offset = linkCount % partitionCount
  const spread = new Array(partitionCount).fill(0)

  return spread.map((_, pos) => (pos + 1 <= offset ? itemsPerPartition + 1 : itemsPerPartition))
}

export const partition = <T>(array: Array<T>, partitionCount: number) => {
  const spread = calculateSpread(array, partitionCount)

  // dynamic programming
  let sum = 0

  const pArr = spread.map((itemCount) => {
    const res = array.slice(sum, sum + itemCount)
    sum += itemCount
    return res
  })

  return pArr
}
