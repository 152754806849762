import styled from 'styled-components/macro'

import { BaseElement } from 'src/components/skeleton/elements/BaseElement'
import { Blend } from 'src/components/skeleton/elements/Blend'
import { Skeleton } from 'src/components/skeleton//Skeleton'

export const UserStatsSkeleton = () => (
  <UserStatsSkeletonWrap>
    <BaseElement width={'100%'} height={50} />
    <Blend height={10} />
    <BaseElement width={'100%'} height={50} />
    <Blend height={10} />
    <BaseElement width={'100%'} height={50} />
  </UserStatsSkeletonWrap>
)

const UserStatsSkeletonWrap = styled(Skeleton)`
  border: none;
  min-height: initial;
`
