import styled from 'styled-components/macro'

import { ReactComponent as PeopleIcon } from 'src/assets/images/people_icon.svg'
import { ReactComponent as MedalIcon } from 'src/assets/images/medal_icon.svg'
import { ReactComponent as GiftboxIcon } from 'src/assets/images/giftbox_icon_alt.svg'
import { Spacer } from 'src/components/Spacer'
import { SectionTitle } from 'src/components/typography/SectionTitle'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { formatNumber } from 'src/utils/formatNumber'
import { useMedia } from 'src/utils/media/useMedia'
import { getIsFetchProductStatsLoading, getProductStats } from 'src/api/product/selectors'
import { fetchProductStats } from 'src/api/product/actions'
import { ExternalLink } from 'src/components/links/ExternalLink'
import { config } from 'src/config'
import { HorisontalRule } from 'src/components/HorisontalRule'
import { theme } from 'src/theme'

type Props = {
  productId: string
}

export const Community = ({ productId }: Props) => {
  const dispatch = useDispatch()

  const productStats = useSelector(getProductStats)
  const isFetchProductStatsLoading = useSelector(getIsFetchProductStatsLoading)

  const { isDesktop } = useMedia()

  useEffect(() => {
    if (!productStats && productId) {
      dispatch(fetchProductStats({ productId }))
    }
  }, [dispatch, productStats, productId])

  return (
    <Container>
      <InnerWrapper>
        {!!productStats?.statistics.winners.length && (
          <>
            <MedalIcon />
            <Spacer v size={8} />
            <StyledSectionTitle>Gratulation an die letzten Gewinner</StyledSectionTitle>
            <Spacer v size={10} />
            {productStats?.statistics.winners.map((winner, index) => (
              <Player key={`winner-${index}`}>
                {`${winner.nickname}, ${winner.city}`}
                <br />
              </Player>
            ))}
            <Spacer v size={isDesktop ? 64 : 40} />
          </>
        )}
        <PeopleIcon />
        <Spacer v size={8} />
        <StyledSectionTitle>
          {isFetchProductStatsLoading
            ? '-'
            : `Heute: ${formatNumber(productStats?.statistics.totalPlaysToday)} mal gespielt`}
        </StyledSectionTitle>
        <StyledSectionTitle>
          {isFetchProductStatsLoading
            ? '-'
            : `Gestern: ${formatNumber(productStats?.statistics.totalPlayersYesterday)} Spieler`}
        </StyledSectionTitle>
        <Spacer v size={isDesktop ? 64 : 40} />
        <HorisontalRule noMargin color={theme.colors.blick.white} />
        <Spacer v size={isDesktop ? 64 : 40} />
        <GiftboxIcon />
        <Spacer v size={8} />
        <StyledSectionTitle>Du magst Spiele und coole Preise?</StyledSectionTitle>
        <Spacer v size={8} />
        <Text>
          <ExternalLink href={config.externalLinks.blickSkiTrophyUrl}>Mach mit bei der Blick Ski Trophy</ExternalLink>!
          Beantworte das tägliche Quiz und tippe auf alle Ski Rennen. <br />
          <br /> Jeden Monat gibt’s tolle Preise zu gewinnen.{' '}
          <ExternalLink href={config.externalLinks.blickSkiTrophyUrl}>Los geht’s</ExternalLink>!
        </Text>
      </InnerWrapper>
    </Container>
  )
}

export const Text = styled.div`
  max-width: 450px;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
`

const Container = styled.div`
  color: ${({ theme }) => theme.colors.blick.white};
  padding: 64px 0;
  margin-bottom: 64px;
  background-color: ${({ theme }) => theme.colors.blick.brand};

  ${({ theme }) => theme.media.isMobile} {
    padding: 40px 16px;
  }
`

const InnerWrapper = styled.div`
  margin: 0 auto;
  max-width: 600px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Player = styled.p`
  margin: 0;
  font-family: ${({ theme }) => theme.fonts.sourceSansPro};
  font-weight: 600;
  font-size: 22px;
  line-height: 34px;
`

export const StyledSectionTitle = styled(SectionTitle)`
  ${({ theme }) => theme.media.isDesktop} {
    line-height: 44px;
  }
`
