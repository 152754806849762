export enum PlayState {
  Default = 'default',
  PlayAgain = 'playAgain',
  OnePlayLeft = 'onePlayLeft',
  NoPlaysLeft = 'noPlaysLeft',
  NoPlaysLeftInCampaign = 'noPlaysLeftInCampaign',
  DidWin = 'didWin',
  NoGame = 'noGame',
  АlreadyWonOnce = 'alreadyWonOnce',
}

export enum SlotMachineState {
  Idle = 'idle',
  WaitingResult = 'waitingResult',
  ResultReceived = 'resultReceived',
  ReadyToAnimate = 'readyToAnimate',
  Animating = 'animating',
  Animated = 'animated',
}
