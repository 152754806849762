import { ThunkAction } from 'redux-thunk'
import { config } from 'src/config'
import { TReduxState } from 'src/redux/store'
import { privateClient } from 'src/utils/clients'
import { getReduxErrorObject, showToastError } from 'src/utils/errors'
import { ActionTypes } from './enums'
import {
  TGetFeedbackAction,
  TGetFeedbackSuccessAction,
  TGetFeedbackFailureAction,
  TGetFeedbackRequest,
  TPostFeedbackRequest,
  TPostFeedbackAction,
  TPostFeedbackFailureAction,
  TPostFeedbackSuccessAction,
  TGetFeedbackResponse,
  TGetDialogResponse,
  TPostDialogRequest,
  TSetForceShowFeedbackAction,
  TClearForceShowFeedbackAction,
} from './types'

export const fetchFeedback =
  (
    requestData: TGetFeedbackRequest,
  ): ThunkAction<
    void,
    TReduxState,
    unknown,
    TGetFeedbackAction | TGetFeedbackSuccessAction | TGetFeedbackFailureAction
  > =>
  async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.GET_FEEDBACK,
      })
      const { campaignId } = requestData
      const url = `${config.api.backend.url}/dialog/feedback/status/${campaignId}`
      const url2 = `${config.api.backend.url}/feedback/campaigns/${campaignId}`

      const results = await Promise.all([
        privateClient.get<TGetDialogResponse>(url),
        privateClient.get<TGetFeedbackResponse>(url2),
      ])

      dispatch({
        type: ActionTypes.GET_FEEDBACK_SUCCESS,
        payload: { ...results[0].data, ...results[1].data },
      })
    } catch (error) {
      showToastError('Error while fetching feedback')

      dispatch({
        type: ActionTypes.GET_FEEDBACK_FAILURE,
        payload: getReduxErrorObject(error),
      })

      showToastError(error, { type: 'error' })
    }
  }

export const postFeedback =
  (
    requestData: TPostFeedbackRequest,
  ): ThunkAction<
    void,
    TReduxState,
    unknown,
    TPostFeedbackAction | TPostFeedbackSuccessAction | TPostFeedbackFailureAction
  > =>
  async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.POST_FEEDBACK,
      })
      const { campaignId, rating, message } = requestData
      const url = `${config.api.backend.url}/feedback/campaigns/${campaignId}`

      await privateClient.post(url, { rating, message })

      dispatch({
        type: ActionTypes.POST_FEEDBACK_SUCCESS,
        payload: requestData,
      })
      dispatch(fetchFeedback({ campaignId }))
    } catch (error) {
      showToastError('Error while fetching feedback')

      dispatch({
        type: ActionTypes.POST_FEEDBACK_FAILURE,
        payload: getReduxErrorObject(error),
      })

      showToastError(error, { type: 'error' })
    }
  }

export const postDialog =
  (
    requestData: TPostDialogRequest,
  ): ThunkAction<
    void,
    TReduxState,
    unknown,
    TPostFeedbackAction | TPostFeedbackSuccessAction | TPostFeedbackFailureAction
  > =>
  async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.POST_FEEDBACK,
      })
      const { campaignId, skipped, doNotAskAgain } = requestData
      const url = `${config.api.backend.url}/dialog/feedback/status/${campaignId}`

      await privateClient.post(url, { skipped, doNotAskAgain })

      dispatch({
        type: ActionTypes.POST_FEEDBACK_SUCCESS,
        payload: requestData,
      })
    } catch (error) {
      showToastError('Error while fetching feedback')

      dispatch({
        type: ActionTypes.POST_FEEDBACK_FAILURE,
        payload: getReduxErrorObject(error),
      })

      showToastError(error, { type: 'error' })
    }
  }

export const setForceShowFeedback = (): TSetForceShowFeedbackAction => ({
  type: ActionTypes.SET_FORCE_SHOW_FEEDBACK,
})

export const clearForceShowFeedback = (): TClearForceShowFeedbackAction => ({
  type: ActionTypes.CLEAR_FORCE_SHOW_FEEDBACK,
})
