import styled, { css } from 'styled-components/macro'

export const buttonTheme = {
  primary: css`
    color: ${({ theme }) => theme.colors.blick.white};
    background: linear-gradient(180deg, #d90201 1.56%, #e20000 44.27%, #c00500 100%);
    ${({ theme }) => theme.media.isDesktop} {
      &:hover {
        background: #e20000;
      }
    }
  `,
  secondary: css`
    color: ${({ theme }) => theme.colors.blick.white};
    background: linear-gradient(180deg, #313131 0%, #484848 34.9%, #242424 78.12%);
    border: 2px solid ${({ theme }) => theme.colors.blick.grey100};
    box-shadow: 1px 4px 0px 0px #0000008f;
    ${({ theme }) => theme.media.isDesktop} {
      &:hover {
        background: linear-gradient(180deg, #0f0f0f 0%, #3d3d3d 34.9%, #000000 78.12%);
      }
    }
    &:active {
      box-shadow: 1px 2px 0px 0px #0000007a;
    }
  `,
  black: css`
    color: ${({ theme }) => theme.colors.blick.white};
    background: ${({ theme }) => theme.colors.blick.grey100};
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    font-family: ${({ theme }) => theme.fonts.goodOffcPro};
    ${({ theme }) => theme.media.isDesktop} {
      &:hover {
        background: ${({ theme }) => theme.colors.blick.grey80};
      }
    }

    ${({ theme }) => theme.media.isMobile} {
      font-weight: 500;
      font-size: 14px;
      line-height: 140%;
      max-width: none;
    }
  `,
}

export const buttonStyles = css`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;

  min-width: 30px;
  max-width: 320px;
  padding: 4px 23px;
  font-family: ${({ theme }) => theme.fonts.goodOffcPro};
  font-size: 22px;
  line-height: 140%;
  font-style: normal;
  font-weight: 900;
  letter-spacing: 0.44px;
  text-transform: uppercase;
  text-decoration: none;

  border: none;
  border-radius: 80px;
  box-sizing: border-box;
  // spacing between multiple elements, e.g. Text + Icon
  > *:not(:first-child) {
    margin-left: 10px;
  }

  svg {
    fill: currentColor !important;
  }

  ${({ theme }) => theme.media.isDesktop} {
    &:hover {
      color: ${({ theme }) => theme.colors.blick.white};
      background-color: #c20000;
    }
  }

  ${({ theme }) => theme.media.isMobile} {
    max-width: 260px;
    font-size: 18px;
    padding: 3px 23px;
  }
`

export type TButtonTheme = keyof typeof buttonTheme

type Props = {
  componentTheme?: TButtonTheme
  fullWidth?: boolean
  disabled?: boolean
  height?: string
}

export const Button = styled.button<Props>`
  ${buttonStyles}
  ${({ fullWidth }) => fullWidth && 'width: 100%; max-width: 100%;'}
  ${({ componentTheme }) => buttonTheme[componentTheme || 'primary']};
  min-height: ${({ height }) => height || '60px'};
  ${({ theme }) => theme.media.isMobile} {
    min-height: ${({ height }) => height || '50px'};
  }
  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      opacity: 0.5;
      box-shadow: none;
    `};
`
