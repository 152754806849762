import { throttle } from 'lodash-es'
import { useEffect, useState } from 'react'
import styled from 'styled-components/macro'

type TIframeEvent = Event & {
  data: {
    height: number
    width: number
    iframeId: string
    type: string
  }
}

type Props = {
  url?: string
}

export const Iframe = ({ url }: Props) => {
  const [iframeHeight, setIframeHeight] = useState(200)

  const handleIframeMessage = throttle((event: TIframeEvent) => {
    const height = event.data.height
    if (height) {
      setIframeHeight(height)
    }
  }, 50)

  useEffect(() => {
    window.addEventListener('message', handleIframeMessage)
  })

  return (
    <StyledIframe
      height={iframeHeight}
      src={url}
      sandbox="allow-forms allow-scripts allow-same-origin allow-top-navigation"
    />
  )
}

const StyledIframe = styled.iframe`
  width: 100%;
  border: none;
`
