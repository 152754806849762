import { useEffect, useState } from 'react'

export const useElementOnScreen = (
  options: IntersectionObserverInit,
  containerRef: React.RefObject<HTMLDivElement>,
) => {
  const [isVisible, setIsVisible] = useState(false)

  const callback = (entries: IntersectionObserverEntry[]) => {
    const [entry] = entries
    setIsVisible(entry.isIntersecting)
  }

  useEffect(() => {
    const current = containerRef.current
    const observer = new IntersectionObserver(callback, options)
    if (!(current instanceof HTMLDivElement)) return
    if (current) observer.observe(current)
    return () => {
      if (current) observer.unobserve(current)
    }
  }, [containerRef, options])

  return [isVisible]
}
