import styled from 'styled-components/macro'
import { ItemSection } from './ItemSection'

type Props = {
  name: string
  description: string
}
export const PrizeInfo = ({ name, description }: Props) => {
  return (
    <InfoWrapper>
      <Name>{name}</Name>
      <Description>{description}</Description>
    </InfoWrapper>
  )
}

const InfoWrapper = styled(ItemSection)`
  ${({ theme }) => theme.media.isMobile} {
    margin-bottom: 16px;
  }
`

const Name = styled.div`
  font-family: ${({ theme }) => theme.fonts.goodOffcPro};
  font-size: 28px;
  line-height: 36px;
  font-weight: 950;
  letter-spacing: 0.02em;
  margin-bottom: 8px;
  ${({ theme }) => theme.media.isMobile} {
    margin-bottom: 10px;
    text-align: center;
  }
`

const Description = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  ${({ theme }) => theme.media.isMobile} {
    text-align: center;
  }
`
