import styled from 'styled-components/macro'

export const Headline = styled.div`
  font-family: ${({ theme }) => theme.fonts.sourceSansPro};
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 23px;
  text-align: center;
`
