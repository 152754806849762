import { ThunkAction } from 'redux-thunk'
import { TReduxState } from 'src/redux/store'

import { ActionTypes } from './enums'
import { getReduxErrorObject, showToastError } from 'src/utils/errors'
import {
  TProduct,
  TFetchProductRequest,
  TFetchProductAction,
  TFetchProductSuccessActions,
  TFetchProductFailureAction,
  TPlayGameRequest,
  TPlayGameAction,
  TPlayGameSuccessAction,
  TPlayGameFailureAction,
  TPlayGameResetAction,
  TGameResult,
  TProductStats,
  TFetchProductStatsAction,
  TFetchProductStatsFailureAction,
  TFetchProductStatsSuccessActions,
} from './types'
import { privateClient } from 'src/utils/clients'
import { config } from 'src/config'
import { getDebugQuery } from 'src/utils/debug'

export const fetchProduct =
  (
    request: TFetchProductRequest,
  ): ThunkAction<
    void,
    TReduxState,
    unknown,
    TFetchProductAction | TFetchProductSuccessActions | TFetchProductFailureAction | TPlayGameResetAction
  > =>
  async (dispatch) => {
    const { productId } = request

    try {
      dispatch({
        type: ActionTypes.FETCH_PRODUCT,
        payload: request,
      })

      dispatch(playGameReset())

      const url = `${config.api.backend.url}/products/${productId}`

      const res = await privateClient.get<TProduct>(url)

      dispatch({
        type: ActionTypes.FETCH_PRODUCT_SUCCESS,
        payload: res.data,
      })
    } catch (err) {
      dispatch({
        type: ActionTypes.FETCH_PRODUCT_FAILURE,
        payload: getReduxErrorObject(err),
      })
    }
  }

export const fetchProductStats =
  (
    request: TFetchProductRequest,
  ): ThunkAction<
    void,
    TReduxState,
    unknown,
    TFetchProductStatsAction | TFetchProductStatsSuccessActions | TFetchProductStatsFailureAction
  > =>
  async (dispatch) => {
    const { productId } = request

    try {
      dispatch({
        type: ActionTypes.FETCH_PRODUCT_STATS,
        payload: request,
      })

      const url = `${config.api.backend.url}/products/${productId}/statistics`

      const res = await privateClient.get<TProductStats>(url)

      dispatch({
        type: ActionTypes.FETCH_PRODUCT_STATS_SUCCESS,
        payload: res.data,
      })
    } catch (err) {
      dispatch({
        type: ActionTypes.FETCH_PRODUCT_STATS_FAILURE,
        payload: getReduxErrorObject(err),
      })
    }
  }

export const playGame =
  (
    request: TPlayGameRequest,
  ): ThunkAction<void, TReduxState, unknown, TPlayGameAction | TPlayGameSuccessAction | TPlayGameFailureAction> =>
  async (dispatch) => {
    const { productId, forceWinPrizeId } = request

    try {
      dispatch({
        type: ActionTypes.PLAY_GAME,
      })

      const url = `${config.api.backend.url}/products/${productId}/play${getDebugQuery(forceWinPrizeId)}`

      const res = await privateClient.post<TGameResult>(url)

      dispatch({
        type: ActionTypes.PLAY_GAME_SUCCESS,
        payload: res.data,
      })
    } catch (err) {
      dispatch({
        type: ActionTypes.PLAY_GAME_FAILURE,
        payload: getReduxErrorObject(err),
      })

      showToastError(err, { type: 'error' })
    }
  }

export const playGameReset = (): TPlayGameResetAction => ({
  type: ActionTypes.PLAY_GAME_RESET,
})
