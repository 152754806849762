import { Markdown } from 'src/components/Markdown'
import { Spacer } from 'src/components/Spacer'
import styled from 'styled-components/macro'

type Props = {
  description?: string
  infoText: string
}

export const SponsoringFooter = ({ description, infoText }: Props) => {
  return (
    <>
      {description && (
        <>
          <StyledMarkdown>{description}</StyledMarkdown>
          <Spacer v size={40} />
        </>
      )}
      <StyledMarkdown>{infoText}</StyledMarkdown>
    </>
  )
}

const StyledMarkdown = styled(Markdown)`
  p {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px;
  }
`
