import { Reducer } from 'redux'

import { getReduxFetchStates, getReduxFailureStates, getReduxSuccessStates } from 'src/utils/redux'
import { ActionTypes } from './enums'
import { TActions, TMarketingState } from './types'

export const initialState: TMarketingState = {
  data: null,
  loading: {
    postSubscription: false,
    postOptin: false,
  },
  error: {
    postSubscription: null,
    postOptin: null,
  },
  resolved: {
    postSubscription: false,
    postOptin: false,
  },
}

export const reducer: Reducer<TMarketingState, TActions> = (state = initialState, action): TMarketingState => {
  switch (action.type) {
    case ActionTypes.POST_OPTIN: {
      return {
        ...state,
        ...getReduxFetchStates(state, 'postOptin'),
      }
    }
    case ActionTypes.POST_OPTIN_SUCCESS: {
      return {
        ...state,
        ...getReduxSuccessStates(state, 'postOptin'),
      }
    }
    case ActionTypes.POST_OPTIN_FAILURE: {
      return {
        ...state,
        ...getReduxFailureStates(state, 'postOptin', action.payload),
      }
    }

    default:
      return state
  }
}
