import { colors } from 'src/theme/colors'
import { fonts } from 'src/theme/fonts'
import { media } from 'src/theme/media'
import { measures } from 'src/theme/measures'
import { zIndex } from 'src/theme/zIndex'

export const theme = {
  colors,
  fonts,
  media,
  measures,
  zIndex,
}
