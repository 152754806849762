import styled from 'styled-components/macro'

import { pixelify } from 'src/utils/style/pixelify'

import { ReactComponent as Icon } from 'src/assets/images/star.svg'

interface IStarIconProps {
  isFilled: boolean
  borderColor?: string
  fillColor?: string
  sizeDesktop?: number
  sizeMobile?: number
}

export const StarIcon = ({ sizeDesktop, sizeMobile, ...rest }: IStarIconProps) => {
  return (
    <Wrapper {...rest}>
      <Star $sizeDesktop={sizeDesktop} $sizeMobile={sizeMobile} />
    </Wrapper>
  )
}

const Star = styled(Icon)<{ $sizeDesktop?: number; $sizeMobile?: number }>`
  padding: 4px;
  width: ${({ $sizeMobile }) => pixelify($sizeMobile || 56)};
  height: ${({ $sizeMobile }) => pixelify($sizeMobile || 56)};

  path {
    stroke-width: 4px;
  }

  ${({ theme }) => theme.media.isDesktop} {
    width: ${({ $sizeDesktop }) => pixelify($sizeDesktop || 56)};
    height: ${({ $sizeDesktop }) => pixelify($sizeDesktop || 56)};
  }
`

const Wrapper = styled.div<IStarIconProps>`
  ${Star} {
    path {
      fill: ${({ fillColor, isFilled }) => (isFilled ? fillColor : 'transparent')};
      stroke: ${({ borderColor, fillColor, isFilled }) => (isFilled ? fillColor : borderColor)};
    }
  }
`
