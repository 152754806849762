import { Reducer } from 'redux'

import { TActions, TAuthState } from './types'
import { ActionTypes } from './enums'

export const initialState: TAuthState = {
  user: null,
  metaData: null,
  loggedIn: false,
  authResolved: false,
  isLoading: true,
}

export const reducer: Reducer<TAuthState, TActions> = (state = initialState, action): TAuthState => {
  switch (action.type) {
    case ActionTypes.AUTH_LOGIN_SUCCESS:
      return {
        ...state,
        user: action.payload.user,
        metaData: action.payload.metaData ? action.payload.metaData : null,
        loggedIn: true,
        authResolved: true,
      }
    case ActionTypes.AUTH_LOGOUT: {
      return {
        ...initialState,
        authResolved: true,
      }
    }
    case ActionTypes.AUTH_CHECK_START: {
      return {
        ...state,
        isLoading: true,
      }
    }
    case ActionTypes.AUTH_CHECK_FINISH: {
      return {
        ...state,
        isLoading: false,
      }
    }
    default:
      return state
  }
}
