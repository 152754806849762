import { Reducer } from 'redux'

import { getReduxFetchStates, getReduxFailureStates, getReduxSuccessStates } from 'src/utils/redux'
import { ActionTypes } from './enums'
import { TActions, TFeedbackState } from './types'

export const initialState: TFeedbackState = {
  data: { feedback: null },
  loading: {
    feedback: false,
  },
  error: {
    feedback: null,
  },
  resolved: {
    feedback: false,
  },
}

export const reducer: Reducer<TFeedbackState, TActions> = (state = initialState, action): TFeedbackState => {
  switch (action.type) {
    case ActionTypes.GET_FEEDBACK: {
      return {
        ...state,
        ...getReduxFetchStates(state, 'feedback'),
      }
    }
    case ActionTypes.GET_FEEDBACK_SUCCESS: {
      return {
        ...state,
        data: { ...state.data, feedback: action.payload },
        ...getReduxSuccessStates(state, 'feedback'),
      }
    }
    case ActionTypes.GET_FEEDBACK_FAILURE: {
      return {
        ...state,
        ...getReduxFailureStates(state, 'feedback', action.payload),
      }
    }

    case ActionTypes.SET_FORCE_SHOW_FEEDBACK: {
      return {
        ...state,
        data: { ...state?.data, forceShowFeedback: true },
      }
    }

    case ActionTypes.CLEAR_FORCE_SHOW_FEEDBACK: {
      return {
        ...state,
        data: { ...state?.data, forceShowFeedback: false },
      }
    }

    default:
      return state
  }
}
