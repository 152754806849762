import { useRef, useState, useEffect, useLayoutEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'

import { getUserEmail, getUserHasMarketingOptin, getUserNickName } from 'src/api/auth/selectors'
import { TSlotMachineConfig, TCampaignSymbol } from 'src/api/product/types'
import { playGame, playGameReset } from 'src/api/product/actions'
import {
  getGameResult,
  getIsPlayGameLoading,
  getProductCurrentCampaign,
  getProductUserConfig,
} from 'src/api/product/selectors'
import { getPlayState } from './utils/utils'
import { PlayState, SlotMachineState } from 'src/enums/slotMachine'
import { TTimerSymbol } from './types'
import { useReels } from './utils/useReels'
import { useNow } from 'src/utils/date/useNow'
import { useProtectedCallback } from 'src/api/auth/hooks'
import { LoginMethods } from 'src/enums/auth'
import { useAudio } from 'src/utils/hooks/useAudio'

import handlerAudioFile from 'src/assets/audio/handler_audio.mp3'
import winningAudioFile from 'src/assets/audio/winning_audio.mp3'
import lostAudioFile from 'src/assets/audio/lost_audio.mp3'
import { useWindowSize } from 'src/utils/hooks/useResizeWindow'
import { debounce } from 'lodash-es'
import { getForceWinPrizeId } from 'src/api/prizes/selectors'
import { trackSlotMachinePlay } from 'src/tracking/gtm/actions'
import { TSlotMachinePosition } from 'src/tracking/gtm/types'
import { getIsAudioEnabled } from 'src/state/app/selectors'
import { TSlotMachineId } from './SlotMachine'
import { clearForceShowFeedback, fetchFeedback } from 'src/api/feedback/actions'
import { TFeedback } from 'src/api/feedback/types'
import { getForceShowFeedback } from 'src/api/feedback/selectors'
import { setIsAudioEnabled } from 'src/state/app/actions'
import { isInThePast } from 'src/utils/date/relativeDate'

export const useSlotMachine = (
  id: TSlotMachineId,
  config: TSlotMachineConfig | null,
  symbols: TCampaignSymbol[],
  productId: string,
  feedback?: TFeedback | null,
  onShowFeedbackModal?: () => void,
) => {
  const dispatch = useDispatch()
  const [windowWidth, windowHeight] = useWindowSize()

  const userNickName = useSelector(getUserNickName)
  const userEmail = useSelector(getUserEmail)
  const gameResult = useSelector(getGameResult)
  const isResultLoading = useSelector(getIsPlayGameLoading)
  const currentUserConfig = useSelector(getProductUserConfig)
  const currentCampaign = useSelector(getProductCurrentCampaign)
  const forceWinPrizeId = useSelector(getForceWinPrizeId)
  const isAudioEnabled = useSelector(getIsAudioEnabled)
  const userHasMarketingOptin = useSelector(getUserHasMarketingOptin)
  const forceShowFeedback = useSelector(getForceShowFeedback)

  const [timerSymbols, setTimerSymbols] = useState<TTimerSymbol[] | null>(null)
  const [slotMachineState, setSlotMachineState] = useState<SlotMachineState>(SlotMachineState.Idle)
  const [playState, setPlayState] = useState<PlayState | null>(null)
  const [isWinningAnimationPlaying, setWinningAnimationPlaying] = useState(false)
  const [wrapperHeight, setWrapperHeight] = useState<number>()
  const [playsCount, setPlaysCount] = useState(0)

  const innerWrapperRef = useRef<HTMLDivElement>(null)

  const cacheRef = useRef<{ animations: (Animation | undefined)[] }>({ animations: [] })
  const reels = useReels(config?.numberOfSpinners || 0)

  const isReelsRunning = !(slotMachineState === SlotMachineState.Idle || slotMachineState === SlotMachineState.Animated)
  const isPlayRunning = isResultLoading || isReelsRunning

  const now = useNow(1, 'second')

  const { audio: handlerAudio } = useAudio(handlerAudioFile)
  const { audio: winningAudio } = useAudio(winningAudioFile)
  const { audio: lostAudio } = useAudio(lostAudioFile)

  const onAudioButtonClick = () => {
    dispatch(setIsAudioEnabled(!isAudioEnabled))
  }

  const onPlay = (event: string, position: TSlotMachinePosition, buttonLabel: string) => {
    trackSlotMachinePlay(position, buttonLabel, !isAudioEnabled, userHasMarketingOptin)
    onPlayCallback(event)
  }

  const onPlayCallback = useProtectedCallback(
    LoginMethods.SLOT_MACHINE,
    id,
    //Need to pass any argument to make it work
    (_: string) => {
      if (playState === PlayState.DidWin) {
        setPlayState(PlayState.АlreadyWonOnce)
        setSlotMachineState(SlotMachineState.Idle)
        return
      }
      if (currentUserConfig?.didAlreadyWin) {
        return
      }
      setSlotMachineState(SlotMachineState.WaitingResult)
      setPlaysCount(playsCount + 1)
      dispatch(playGame({ productId, forceWinPrizeId }))

      handlerAudio.play()
    },
  )

  useLayoutEffect(() => {
    if (slotMachineState !== SlotMachineState.WaitingResult || !gameResult) {
      return
    }

    setSlotMachineState(SlotMachineState.ResultReceived)
  }, [slotMachineState, gameResult])

  useLayoutEffect(() => {
    if (slotMachineState !== SlotMachineState.ResultReceived) {
      return
    }

    setSlotMachineState(SlotMachineState.ReadyToAnimate)
  }, [slotMachineState])

  useEffect(() => {
    if (slotMachineState !== SlotMachineState.Animated) {
      return
    }

    cacheRef.current.animations.forEach((animation) => animation?.cancel())

    setSlotMachineState(SlotMachineState.Idle)

    const shouldShowFeedbackModal = () => {
      if (forceShowFeedback) {
        dispatch(clearForceShowFeedback())
        return true
      }
      if (
        !feedback ||
        feedback.hasPlacedFeedback ||
        feedback.doNotAskAgain ||
        (feedback.skipped && feedback.numberOfPlaysAfterSkipped && feedback.numberOfPlaysAfterSkipped < 6)
      ) {
        return false
      }
      if (
        currentUserConfig?.totalPlays !== undefined &&
        currentUserConfig?.totalPlays + playsCount >= 6 &&
        !feedback.skipped
      ) {
        return true
      }
      if (feedback.skipped && feedback.numberOfPlaysAfterSkipped && feedback.numberOfPlaysAfterSkipped >= 6) {
        return true
      }
    }

    if (shouldShowFeedbackModal()) {
      onShowFeedbackModal?.()
    }

    if (currentCampaign && !feedback) {
      dispatch(fetchFeedback({ campaignId: currentCampaign.id }))
    }
  }, [
    currentCampaign,
    currentUserConfig?.totalPlays,
    dispatch,
    feedback,
    forceShowFeedback,
    onShowFeedbackModal,
    playState,
    playsCount,
    slotMachineState,
  ])

  useEffect(() => {
    if (slotMachineState !== SlotMachineState.ReadyToAnimate) {
      return
    }

    const animate = async () => {
      setSlotMachineState(SlotMachineState.Animating)

      cacheRef.current.animations = await Promise.all(reels.map((reel) => reel.ref.current?.animate()))

      setSlotMachineState(SlotMachineState.Animated)
    }

    animate()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slotMachineState])

  // Change play state
  useEffect(() => {
    if (!isReelsRunning && currentCampaign) {
      const playState = getPlayState(gameResult, currentUserConfig, currentCampaign)

      setPlayState(playState)

      dispatch(playGameReset())

      if (playState === PlayState.DidWin) {
        winningAudio.play()

        setWinningAnimationPlaying(true)
      }

      if (playState === PlayState.NoPlaysLeft) {
        lostAudio.play()
      }
    }
    // Don't pass all the arguments to dependencies as we need result updated when spinning is over, with updating only this one dependency all remaining dependencies updating automatically
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReelsRunning])

  // // Set timer symbols
  useEffect(() => {
    const shouldShowTimer =
      (playState === PlayState.Default ||
        playState === PlayState.OnePlayLeft ||
        playState === PlayState.NoPlaysLeft ||
        (playState === PlayState.PlayAgain && currentUserConfig && currentUserConfig.playsLeft > 0)) &&
      !!currentCampaign?.endDate &&
      !isInThePast(currentCampaign) &&
      !isPlayRunning
    if (shouldShowTimer) {
      const timeLeft = dayjs.duration(dayjs(currentCampaign.endDate).diff(now))

      const daysLeft = timeLeft.get('days')
      const hoursLeft = timeLeft.get('hours')
      const totalHoursLeft = daysLeft > 0 ? daysLeft * 24 + hoursLeft : hoursLeft
      const minutesLeft = timeLeft.get('minutes')
      const secondsLeft = timeLeft.get('seconds')
      const timerSymbols = [
        { value: totalHoursLeft, unit: 'STD' },
        { value: minutesLeft, unit: 'Min' },
        { value: secondsLeft, unit: 'SEK' },
      ]

      return setTimerSymbols(timerSymbols)
    }
  }, [playState, currentCampaign, isPlayRunning, now, currentUserConfig])

  // used to animate the wrapper when playState changes
  useEffect(
    () =>
      debounce(() => {
        if (innerWrapperRef && innerWrapperRef.current) {
          setWrapperHeight(innerWrapperRef.current.offsetHeight)
        }
      }, 100),
    [playState, innerWrapperRef, windowWidth, windowHeight],
  )

  return {
    gameResult,
    reels,
    onPlay,
    isPlayRunning,
    currentUserConfig,
    playState,
    slotMachineState,
    timerSymbols,
    userNickName,
    userEmail,
    isWinningAnimationPlaying,
    setWinningAnimationPlaying,
    innerWrapperRef,
    wrapperHeight,
    title: currentCampaign?.title,
    isAudioEnabled,
    onAudioButtonClick,
  }
}
