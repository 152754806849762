import { ActionCreator } from 'redux'
import { ThunkAction } from 'redux-thunk'
import { AxiosError } from 'axios'

import { config } from 'src/config'
import { TReduxState, store } from 'src/redux/store'
import { privateClient } from 'src/utils/clients'
import { LoginMethods } from 'src/enums/auth'
import { getAuthLoginCase, getProductFromPath, getSelfUrl } from 'src/utils/auth'
import { ActionTypes } from './enums'
import {
  TAuthUser,
  TLoginAction,
  TLoginSuccessAction,
  TLogoutAction,
  TLoginCheckStartAction,
  TLoginCheckFinishAction,
  TLoginOptions,
  TAuthMetaData,
} from './types'
import { getIsLoggedIn } from './selectors'
import { trackUserLogin } from 'src/tracking/gtm/actions'
import { getCurrentCampaignId } from 'src/api/product/selectors'

export const login: ActionCreator<TLoginAction> = (method: LoginMethods, { returnUrl = '' }: TLoginOptions = {}) => {
  const selfUrl = returnUrl.match(/:\/\//) ? returnUrl : getSelfUrl() + returnUrl

  const productId = getProductFromPath()
  const loginCase = getAuthLoginCase()
  const campaignId = getCurrentCampaignId(store.getState())

  trackUserLogin(method)

  document.cookie = `loginRedirect=${selfUrl}; Domain=.blick.ch; Path=/`
  window.location.href =
    `${config.api.onelog.url}/authorize` +
    `?client_id=${config.api.onelog.clientId}` +
    `&redirect_uri=${config.api.onelog.redirectUrl}` +
    `&login_case=${loginCase}` +
    `&response_type=code` +
    `&scope=${config.api.onelog.scope}` +
    `&source=GAME_gameofchance_${productId}_${campaignId}_${method}` +
    `&state=blickch`

  return {
    type: ActionTypes.AUTH_LOGIN,
  }
}

export const loginSuccess =
  (payload: {
    user: TAuthUser
    metaData?: TAuthMetaData
  }): ThunkAction<void, TReduxState, unknown, TLoginSuccessAction> =>
  async (dispatch) => {
    dispatch({
      type: ActionTypes.AUTH_LOGIN_SUCCESS,
      payload,
    })
  }

export const logout: ActionCreator<TLogoutAction> = ({ returnUrl = '' }: { returnUrl?: string }) => {
  const selfUrl = returnUrl.match(/:\/\//) ? returnUrl : getSelfUrl() + returnUrl

  document.cookie = `logoutRedirect=${selfUrl}; Domain=.blick.ch; Path=/`
  window.location.href =
    `${config.api.onelog.url}/v2/logout` +
    `?client_id=${config.api.onelog.clientId}` +
    `&returnTo=${config.api.onelog.logoutUrl}`

  return {
    type: ActionTypes.AUTH_LOGOUT,
  }
}

export const checkAuth =
  (): ThunkAction<
    void,
    TReduxState,
    unknown,
    TLoginSuccessAction | TLogoutAction | TLoginCheckStartAction | TLoginCheckFinishAction
  > =>
  async (dispatch) => {
    const url = `${config.api.backend.url}/session`
    const metadataUrl = `${config.api.onelog.metadataUrl}`
    let res
    let metadataRes

    dispatch({
      type: ActionTypes.AUTH_CHECK_START,
    })

    try {
      res = await privateClient.get<{ session: TAuthUser }>(url)
      if ((res as unknown as AxiosError).response?.status === 401) {
        throw new Error((res as unknown as AxiosError).response?.data as string) // Successful request with customized BE error message needs to be handled separately.
      }
    } catch (err) {
      dispatch({
        type: ActionTypes.AUTH_LOGOUT,
      })
    }

    try {
      metadataRes = await privateClient.get<{ metadata: TAuthMetaData }>(metadataUrl)
    } catch (err) {
      // trackError(err)
    }

    // Offline? User should not be logged out.
    if (!res) {
      return dispatch({
        type: ActionTypes.AUTH_CHECK_FINISH,
      })
    }

    const isLoggedIn = Boolean(res.data?.session)

    const isStoreLoggedIn = getIsLoggedIn(store.getState())

    // Login the redux store and update session data
    if (isLoggedIn) {
      dispatch(loginSuccess({ user: res.data.session, metaData: metadataRes?.data.metadata }))
    }

    // Logout the redux store, if it isn't logged in
    if (!isLoggedIn && isStoreLoggedIn) {
      dispatch({
        type: ActionTypes.AUTH_LOGOUT,
      })
    }

    dispatch({
      type: ActionTypes.AUTH_CHECK_FINISH,
    })
  }
