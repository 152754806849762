import axios from 'axios'
import { identity } from 'lodash-es'

const successInterceptor = identity
const errorInterceptor = (error: unknown) => {
  throw error
}

// Always make sure to use one of the clients for your requests!

// For public resources
export const publicClient = axios.create()
publicClient.interceptors.response.use(successInterceptor, errorInterceptor)

// For protected resources
export const privateClient = axios.create()
privateClient.defaults.withCredentials = true
privateClient.interceptors.response.use(successInterceptor, errorInterceptor)
