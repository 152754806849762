import { Image } from 'src/components/Image'
import styled from 'styled-components/macro'
import { SponsoringSlider } from './SponsoringSlider'

type Props = {
  logoUrl: string
  imageUrls: string[]
  logoWidth: number
}

export const SponsoringHeader = ({ logoUrl, imageUrls, logoWidth }: Props) => {
  return (
    <HeaderWrapper>
      <HeaderSection>
        <SponsoringSlider imageUrls={imageUrls} />
      </HeaderSection>
      <HeaderSection>
        <LogoWrapper>
          <PresentationText>PROMOTION MIT</PresentationText>
          <br />
          <Logo src={logoUrl} $width={logoWidth} />
        </LogoWrapper>
      </HeaderSection>
    </HeaderWrapper>
  )
}

const HeaderWrapper = styled.div`
  display: flex;
  margin-bottom: 56px;
  ${({ theme }) => theme.media.isMobile} {
    flex-direction: column-reverse;
    align-items: center;
  }
`

const LogoWrapper = styled.div`
  ${({ theme }) => theme.media.isMobile} {
    width: fit-content;
  }
`

const HeaderSection = styled.div`
  max-width: 313px;
  margin-right: 24px;
  ${({ theme }) => theme.media.isMobile} {
    max-width: 343px;
    margin-right: 0px;
    &:last-child {
      width: 343px;
      margin-bottom: 24px;
    }
  }
`

const PresentationText = styled.span`
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
`

const Logo = styled(Image)<{ $width: number }>`
  margin-top: 16px;
  width: ${({ $width }) => $width}%;
`
