import styled from 'styled-components/macro'

export const SectionRow = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;
  line-height: 26px;
  &:not(:last-child) {
    margin-bottom: 10px;
  }
`
