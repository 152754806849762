export interface IFooterLinkType {
  label: string
  url: string
  override?: () => void
}

export const blickLinks: IFooterLinkType[] = [
  { label: 'Impressum', url: 'https://www.blick.ch/services/impressum/impressum-blick-gruppe-id5697115.html' },
  { label: 'Abonnement', url: 'https://abo.blick.ch' },
  {
    label: 'E-Paper',
    url: 'https://abo.blick.ch/landing-page/index.html#/?appId=133f849c-fd6c-40ad-a8fa-2845f2d81d32',
  },
  { label: 'Rubrikanzeigen', url: 'https://oas.admeira.ch/?em_client=rgb' },
  { label: 'Gutscheine', url: 'https://gutscheine.blick.ch/' },
  { label: 'Dossiers', url: 'https://www.blick.ch/dossiers/' },
  { label: 'Webarchiv', url: 'https://www.blick.ch/services/webarchiv/' },
  {
    label: 'Newsletter',
    url: 'https://www.blick.ch/news/immer-aktuell-informiert-abonnieren-sie-den-blick-ch-newsletter-id69119.html',
  },
  { label: 'Feedback', url: 'https://www.blick.ch/services/sagen-sie-uns-ihre-meinung-zur-blick-beta-id15000846.html' },
  { label: 'Werbung', url: 'https://www.ringier-advertising.ch/portfolio/digital/blick-ch/' },
  { label: 'Datenschutzbestimmungen', url: 'https://www.blick.ch/services/datenschutzbestimmungen-id151553.html' },
  {
    label: 'Einstellungen zum Datenschutz',
    url: 'https://www.blick.ch/services/datenschutz/opt-out/',
    override: () => window.OneTrust?.ToggleInfoDisplay(),
  },
  { label: 'AGB', url: 'https://www.blick.ch/services/allgemeine-nutzungsbedingungen-id158845.html' },
]

export const ringierLinks: IFooterLinkType[] = [
  { label: 'anibis.ch', url: 'https://www.anibis.ch/de/default.aspx' },
  { label: 'AutoScout24', url: 'https://www.autoscout24.ch/' },
  { label: 'Beobachter', url: 'https://www.beobachter.ch/' },
  { label: 'Bolero (de)', url: 'https://www.boleromagazin.ch/' },
  { label: 'Cash', url: 'https://cash.ch/' },
  { label: 'DeinDeal', url: 'https://www.deindeal.ch/de/' },
  { label: 'financescout24.ch', url: 'https://www.financescout24.ch/de' },
  { label: 'Gate24', url: 'https://www.gate24.ch/' },
  { label: 'Geschenkidee', url: 'https://www.geschenkidee.ch/' },
  { label: 'GetYourLawyer', url: 'https://www.getyourlawyer.ch/' },
  { label: 'Glückspost', url: 'https://www.glueckspost.ch/' },
  { label: 'Guider', url: 'https://www.guider.ch/' },
  { label: 'ImmoScout24', url: 'https://www.immoscout24.ch/' },
  { label: 'Jobs.ch', url: 'https://www.jobs.ch/' },
  { label: 'JobScout24', url: 'https://www.jobscout24.ch/' },
  { label: "l'Illustré", url: 'https://www.illustre.ch/' },
  { label: 'Radio Energy', url: 'https://energy.ch/' },
  { label: 'Ringier Sports AG', url: 'https://ringiersports.ch/' },
  { label: 'Schweizer Illustrierte', url: 'https://www.schweizer-illustrierte.ch/' },
  { label: 'Scout24', url: 'https://scout24.ch/' },
  { label: 'Style', url: 'https://style-magazin.ch/' },
  { label: 'Ticketcorner', url: 'https://www.ticketcorner.ch/' },
  { label: 'Verkaufen.ch', url: 'https://www.verkaufen.ch/' },
]
