import styled from 'styled-components/macro'

type Props = {
  $align?: 'left' | 'center' | 'right'
}

export const ModalText = styled.p<Props>`
  margin: 0;
  font-size: 18px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0.01em;
  text-align: ${({ $align }) => $align || 'left'};
  ${({ theme }) => theme.media.isMobile} {
    text-align: left;
  }
`
