import { TCampaign } from 'src/api/product/types'
import { getNow } from './getNow'
import dayjs from 'dayjs'

export const isLastDay = (currentCampaign: TCampaign) => {
  const endDate = dayjs(currentCampaign.endDate)
  const now = getNow()
  const diff = endDate.diff(now, 'hours')
  return diff < 24
}

export const isInThePast = (currentCampaign: TCampaign) => {
  const endDate = dayjs(currentCampaign.endDate)
  const now = getNow()
  return now.isAfter(endDate)
}
