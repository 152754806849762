import { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { DurationUnitType } from 'dayjs/plugin/duration'
import { getNow } from './getNow'

const MIN_TIMEOUT = 50
const MAX_TIMEOUT = 2_000_000_000

//
//
export const useNow = (duration: number, units: DurationUnitType) => {
  const [now, setNow] = useState(() => getNow())

  const interval = Math.max(MIN_TIMEOUT, dayjs.duration(duration, units).asMilliseconds())
  if (interval <= 0) {
    throw new Error('interval should be positive')
  }

  // let nextTime be of type number to useEffect can strict check dependency value
  let nextTime: number = Math.ceil((now.valueOf() + 1) / interval) * interval

  if (nextTime - now.valueOf() > MAX_TIMEOUT) {
    nextTime = Math.ceil((now.valueOf() + 1) / MAX_TIMEOUT) * MAX_TIMEOUT
  }

  useEffect(() => {
    const timeoutHandle = setTimeout(() => setNow(getNow()), nextTime - getNow().valueOf())
    return () => clearTimeout(timeoutHandle)
  }, [nextTime])

  return now
}
