import styled from 'styled-components/macro'

import { ReactComponent as BellIcon } from 'src/assets/images/bellRing_icon.svg'
import { SectionTitle } from 'src/components/typography/SectionTitle'
import { Iframe } from './Iframe'
import { Spacer } from 'src/components/Spacer'
import { config } from 'src/config'
import { useSelector } from 'react-redux'
import { getUserHasMarketingOptin } from 'src/api/auth/selectors'

const getTitleText = (isSubscribed: boolean | undefined) =>
  isSubscribed
    ? 'Du hast den Gewinnspiel-Newsletter abonniert. So verpasst du kein Gewinnspiel mehr.'
    : 'Abonniere unseren Newsletter um kein Gewinnspiel mehr zu verpassen'

export const SubscriptionSection = () => {
  const isSubscribed = useSelector(getUserHasMarketingOptin)
  return (
    <SectionWrapper>
      <BellIcon />
      <Spacer size={12} v />
      <TitleWrapper>
        <StyledSectionTitle>{getTitleText(isSubscribed)}</StyledSectionTitle>
      </TitleWrapper>
      {!isSubscribed && (
        <>
          <Spacer size={24} v />
          <IframeWrapper>
            <Iframe url={config.externalLinks.blickSignUpIframe} />
          </IframeWrapper>
        </>
      )}
    </SectionWrapper>
  )
}

const SectionWrapper = styled.div<{ height?: number }>`
  padding: 40px 0 24px;

  ${({ theme }) => theme.media.isMobile} {
    padding: 24px 0;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
`

const StyledSectionTitle = styled(SectionTitle)`
  ${({ theme }) => theme.media.isDesktop} {
    line-height: 44px;
  }
`

const TitleWrapper = styled.div`
  width: 654px;
  text-align: center;
  ${({ theme }) => theme.media.isMobile} {
    width: auto;
    padding: 0 24px;
  }
`

const IframeWrapper = styled.div`
  width: 621px;
  ${({ theme }) => theme.media.isMobile} {
    width: 100%;
    padding: 0 16px;
  }
`
