import { createGlobalStyle } from 'styled-components/macro'
import 'react-toastify/dist/ReactToastify.css'

import { TOAST_CLASSNAMES, CSS_SELECTORS, MOBILE_BOTTOM_MARGIN } from './constants'

// Override the default toast style
// Make sure to ONLY change visuals, no positioning
export const GlobalToastStyle = createGlobalStyle`
  ${CSS_SELECTORS.Container} {
    min-width: 320px;
    ${({ theme }) => theme.media.isMobile} {
      top: 16px;

      padding: 0 16px;
      margin: 0 auto;

      width: 100%;
      max-width: 100%;
    }

    ${({ theme }) => theme.media.isDesktop} {
      top: 24px;
      padding: 4px 16px;

      width: auto;
      max-width: ${({ theme }) => theme.measures.contentWidth};
    }
  }

  ${CSS_SELECTORS.Toast} {
    min-height: 0px;
    min-width: 320px;
    max-width: 100%;
    width: max-content; 

    padding: 16px;
    margin-left: auto;
    margin-right: auto;

    ${({ theme }) => theme.media.isMobile} {
      margin-bottom: ${MOBILE_BOTTOM_MARGIN};
    }

    font-family: ${({ theme }) => theme.fonts.sourceSansPro};
    font-size: 18px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0.01em;
    color: ${({ theme }) => theme.colors.blick.grey100};
    border-radius:8px;
    border: 1px solid ${({ theme }) => theme.colors.blick.grey70};
    background-color: ${({ theme }) => theme.colors.blick.grey10};
    &.${TOAST_CLASSNAMES.success} {
      background-color: #EBF5F0;
      border: 1px solid ${({ theme }) => theme.colors.states.success};
    }
    &.${TOAST_CLASSNAMES.warning} {
      background-color: #FFF7E3;
      border: 1px solid ${({ theme }) => theme.colors.states.warning};
    }
    &.${TOAST_CLASSNAMES.error} {
      background-color: #fff3f2;
      border: 1px solid  ${({ theme }) => theme.colors.states.error};
    }
  }

  ${CSS_SELECTORS.Body} {
    padding: 0;
    padding-right: 90px;
    margin: 0;
    background: transparent;
    align-items: start;
    ${({ theme }) => theme.media.isMobile} {
      padding-right: 8px;
    }
  }

  ${CSS_SELECTORS.CloseButton} {
    top: 0;
    right: 0;

    color: ${({ theme }) => theme.colors.blick.white};
    background-color: ${({ theme }) => theme.colors.blick.grey100};
    opacity: 1 !important;
  }

  ${CSS_SELECTORS.CloseButtonSVGs} {
    fill: currentColor;
  }

  ${CSS_SELECTORS.Icon} {
    margin-top: 2px;
    height: 18px;
    width: 18px;
  }

`
