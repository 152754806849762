export const SLOT_MACHINE_WIDTH_DESKTOP = 546
export const SLOT_MACHINE_HEIGHT_DESKTOP = 297
export const SLOT_MACHINE_WIDTH_MOBILE = 325
export const SLOT_MACHINE_HEIGHT_MOBILE = 186

export const TOP_PANEL_WIDTH_DESKTOP = 365
export const TOP_PANEL_HEIGHT_DESKTOP = 40
export const TOP_PANEL_WIDTH_MOBILE = 240
export const TOP_PANEL_HEIGHT_MOBILE = 24

export const SIDE_PANEL_WIDTH_DESKTOP = 19
export const SIDE_PANEL_WIDTH_MOBILE = 11

export const HANDLER_WIDTH_DESKTOP = 64
export const HANDLER_WIDTH_MOBILE = 38
export const HANDLER_HEIGHT_DESKTOP = 313
export const HANDLER_HEIGHT_MOBILE = 185

export const SYMBOL_HEIGHT_DESKTOP = 90
export const SYMBOL_HEIGHT_MOBILE = 56
export const SYMBOLS_GAP_DESKTOP = 24
export const SYMBOLS_GAP_MOBILE = 16

export const REEL_WIDTH_DESKTOP = 130
export const REEL_WIDTH_MOBILE = 80
export const REEL_HEIGHT_DESKTOP = 207
export const REEL_HEIGHT_MOBILE = 126
export const REEL_BORDER_DESKTOP = 5
export const REEL_BORDER_MOBILE = 4

export const HANDLER_ANIMATION_DURATION = 1200

export const DEFAULT_PLAYS_PER_DAY = 3
