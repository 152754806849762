import styled from 'styled-components/macro'

import { Image } from 'src/components/Image'
import { SYMBOL_HEIGHT_DESKTOP, SYMBOLS_GAP_DESKTOP, SYMBOL_HEIGHT_MOBILE, SYMBOLS_GAP_MOBILE } from './constants'

type Props = {
  imageUrl: string
}

export const CampaignSymbol = ({ imageUrl }: Props) => {
  return (
    <SymbolWrap>
      <Image src={imageUrl} />
    </SymbolWrap>
  )
}

export const SymbolWrap = styled.div`
  margin: 0 auto;
  width: ${SYMBOL_HEIGHT_DESKTOP}px;
  height: ${SYMBOL_HEIGHT_DESKTOP}px;
  padding: ${SYMBOLS_GAP_DESKTOP / 2}px;
  box-sizing: content-box;
  ${Image} {
    object-fit: cover;
    width: 100%;
    height: 100%;

    /* enable gpu accelaration, fixes iOS flicker */
    transform: translate3d(0, 0, 0);
  }

  ${({ theme }) => theme.media.isMobile} {
    width: ${SYMBOL_HEIGHT_MOBILE}px;
    height: ${SYMBOL_HEIGHT_MOBILE}px;
    padding: ${SYMBOLS_GAP_MOBILE / 2}px;
  }
`
