import styled from 'styled-components/macro'
import { Route, Routes } from 'react-router-dom'

import { config } from 'src/config'
import { Path } from 'src/router/enums'

import { DevToolBar } from 'src/containers/DevToolBar'
import { Home } from 'src/routes/Home'
import { Product } from 'src/routes/Product'
import { Header } from 'src/containers/Header'
import { useDispatch } from 'react-redux'
import { checkAuth } from 'src/api/auth/actions'
import { useEffect } from 'react'
import { Footer } from 'src/containers/Footer'
import { waitForConsentChange } from 'src/tracking/gtm/actions'

export const AppLayout = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(checkAuth())
  })

  useEffect(() => {
    waitForConsentChange()
  }, [])

  return (
    <>
      <Header />
      <AppEntryPoint>
        {config.isDev && <DevToolBar />}
        <Routes>
          <Route path="*" element={<>Page not found</>} />

          <Route path={Path.Home} element={<Home />} />
          <Route path={Path.Product} element={<Product />} />
        </Routes>
        <Footer />
      </AppEntryPoint>
    </>
  )
}

const AppEntryPoint = styled.div`
  min-height: 100vh;
`
