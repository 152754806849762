import { forwardRef, Ref, useRef, useMemo, useImperativeHandle } from 'react'
import styled from 'styled-components/macro'

import { TCampaignSymbol } from 'src/api/product/types'
import {
  REEL_HEIGHT_DESKTOP,
  REEL_WIDTH_DESKTOP,
  SYMBOLS_GAP_DESKTOP,
  SYMBOL_HEIGHT_DESKTOP,
  REEL_BORDER_DESKTOP,
  SYMBOL_HEIGHT_MOBILE,
  SYMBOLS_GAP_MOBILE,
  REEL_BORDER_MOBILE,
  REEL_HEIGHT_MOBILE,
  REEL_WIDTH_MOBILE,
} from './constants'
import { isCampaignSymbol, getFactor, getReelNextSymbols, getSymbolsWithElementId } from './utils/utils'
import { SlotMachineState } from 'src/enums/slotMachine'
import { getId } from './utils/getId'
import { getRandomNumber } from 'src/utils/other'
import { useAudio } from 'src/utils/hooks/useAudio'

import { CampaignSymbol } from './CampaignSymbol'
import { TTimerSymbol } from './types'
import { TimerSymbol } from './TimerSymbol'

import stopSpinAudioFile from 'src/assets/audio/stop_spin_audio.mp3'

type ReelProps = {
  index: number
  symbols: TCampaignSymbol[]
  winnerSymbolId: number | null
  slotMachineState: SlotMachineState
  timerSymbol: TTimerSymbol | null
}

export type TReelAPI = {
  animate: () => Promise<Animation | undefined>
}

export const Reel = forwardRef(
  ({ symbols, winnerSymbolId, index, slotMachineState, timerSymbol }: ReelProps, ref: Ref<TReelAPI>) => {
    const cacheRef = useRef({
      animation: null as Animation | null,
      finalSymbols: [] as TCampaignSymbol[],
      prevFinalSymbols: [] as TCampaignSymbol[],
      animatingSymbols: [] as (TCampaignSymbol | TTimerSymbol)[],
    })
    const reelElementRef = useRef<HTMLDivElement>(null)

    const { audio: stopSpinAudio } = useAudio(stopSpinAudioFile)

    const animatingSymbols = useMemo(() => {
      const nextSymbols = cacheRef.current.prevFinalSymbols.length
        ? [...cacheRef.current.prevFinalSymbols]
        : timerSymbol
        ? [...symbols.slice(0, 1), timerSymbol, ...symbols.slice(1, 2)].map((symbol, idx) => ({
            ...symbol,
            elementId: idx, //Provide constant elementId to avoid initial rerenders
          }))
        : symbols.slice(0, 3).map((symbol, idx) => ({
            ...symbol,
            elementId: idx, //Provide constant elementId to avoid initial rerenders
          }))

      if (slotMachineState === SlotMachineState.Idle || slotMachineState === SlotMachineState.WaitingResult) {
        cacheRef.current.animatingSymbols = []
        return nextSymbols
      }
      if (cacheRef.current.animatingSymbols.length) {
        return cacheRef.current.animatingSymbols
      }

      const winnerSymbol = symbols.find((symbol) => symbol.id === winnerSymbolId)
      cacheRef.current.finalSymbols = getSymbolsWithElementId(
        getReelNextSymbols(winnerSymbol as TCampaignSymbol, symbols),
      ) as TCampaignSymbol[]

      for (let i = 3; i < 3 + Math.floor(getFactor(index)) * 10; i++) {
        const symbol = {
          ...symbols[getRandomNumber(symbols.length)],
          elementId: getId(),
        }

        nextSymbols.push(symbol)
      }

      cacheRef.current.animatingSymbols = [...nextSymbols.slice(0, -3), ...cacheRef.current.finalSymbols]

      return cacheRef.current.animatingSymbols

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [slotMachineState, timerSymbol])

    useImperativeHandle(
      ref,
      () => ({
        animate: async () => {
          if (!reelElementRef.current) {
            return
          }

          const factor = getFactor(index)
          const duration = factor * 1000

          const animation = reelElementRef.current.animate(
            [
              {
                filter: 'none',
              },
              {
                transform: `none`,
                filter: 'blur(2px)',
                offset: 0.05,
              },
              {
                transform: `translateY(-${((Math.floor(factor) * 10) / (3 + Math.floor(factor) * 10)) * 100}%)`,
                filter: 'blur(0)',
              },
            ],
            {
              fill: 'forwards',
              duration,
              easing: 'ease-in-out',
            },
          )

          animation.commitStyles()
          animation.play()

          await new Promise((resolve) => (animation.onfinish = resolve))

          cacheRef.current.prevFinalSymbols = cacheRef.current.finalSymbols

          stopSpinAudio.play()

          return animation
        },
      }),
      [index, reelElementRef, stopSpinAudio],
    )

    return (
      <>
        <ReelWrap>
          <SymbolsWrap ref={reelElementRef}>
            {animatingSymbols.map((symbol, id) => {
              if (isCampaignSymbol(symbol)) {
                return <CampaignSymbol imageUrl={symbol.imageUrl} key={symbol.elementId} />
              }
              return <TimerSymbol symbol={symbol} key={symbol.elementId} />
            })}
          </SymbolsWrap>
        </ReelWrap>
      </>
    )
  },
)

Reel.displayName = 'Reel'

const ReelWrap = styled.div`
  position: relative;
  overflow: hidden;
  width: ${REEL_WIDTH_DESKTOP}px;
  height: ${REEL_HEIGHT_DESKTOP}px;
  border: ${REEL_BORDER_DESKTOP}px solid #d3841c;
  border-bottom-color: #ffeab0;
  background-color: ${({ theme }) => theme.colors.blick.white};
  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      180deg,
      rgba(31, 31, 31, 0.6) 0%,
      rgba(31, 31, 31, 0) 21.85%,
      rgba(31, 31, 31, 0) 51.56%,
      rgba(31, 31, 31, 0) 79.5%,
      rgba(31, 31, 31, 0.6) 100%
    );
  }
  ${({ theme }) => theme.media.isMobile} {
    width: ${REEL_WIDTH_MOBILE}px;
    height: ${REEL_HEIGHT_MOBILE}px;
    border: ${REEL_BORDER_MOBILE}px solid #d3841c;
  }
`

const SymbolsWrap = styled.div`
  position: absolute;
  width: 100%;
  top: -${(SYMBOL_HEIGHT_DESKTOP * 3 + SYMBOLS_GAP_DESKTOP * 3 + REEL_BORDER_DESKTOP * 2 - REEL_HEIGHT_DESKTOP) / 2}px;
  ${({ theme }) => theme.media.isMobile} {
    top: -${(SYMBOL_HEIGHT_MOBILE * 3 + SYMBOLS_GAP_MOBILE * 3 + REEL_BORDER_MOBILE * 2 - REEL_HEIGHT_MOBILE) / 2}px;
  }
`
