import styled from 'styled-components/macro'

import { BaseElement } from 'src/components/skeleton/elements/BaseElement'
import { Skeleton } from 'src/components/skeleton//Skeleton'
import { useMedia } from 'src/utils/media/useMedia'

export const PrizesSkeleton = () => {
  const { isDesktop } = useMedia()

  return (
    <SkeletonWrap>
      <BaseElement width={'100%'} height={isDesktop ? '648px' : '100vh'} />
    </SkeletonWrap>
  )
}

const SkeletonWrap = styled(Skeleton)`
  padding: 0;
`
