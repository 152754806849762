import { ThemeProvider } from 'styled-components/macro'
import { Provider } from 'react-redux'
import { store } from 'src/redux/store'
import { BrowserRouter } from 'react-router-dom'

import { theme } from 'src/theme'
import { GlobalStyle } from 'src/components/GlobalStyle'
import { GlobalToastStyle } from 'src/containers/toast/GlobalToastStyle'
import { ToastContainer } from 'src/containers/toast/ToastContainer'
import { DrawerProvider } from 'src/drawer'
import { AppLayout } from 'src/AppLayout'

import 'src/assets/fonts/fonts.css'
import 'src/utils/date/initDates'

export const App = () => {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <GlobalToastStyle />
        <BrowserRouter>
          <DrawerProvider>
            <AppLayout />
          </DrawerProvider>
        </BrowserRouter>
        <ToastContainer />
      </ThemeProvider>
    </Provider>
  )
}
