import { ThunkAction } from 'redux-thunk'
import { TReduxState } from 'src/redux/store'

import { ActionTypes } from './enums'
import { getReduxErrorObject } from 'src/utils/errors'
import {
  TUserStats,
  TFetchUserStatsRequest,
  TFetchUserStatsAction,
  TFetchUserStatsSuccessAction,
  TFetchUserStatsFailureAction,
} from './types'
import { privateClient } from 'src/utils/clients'
import { config } from 'src/config'

export const fetchUserStats =
  (
    request: TFetchUserStatsRequest,
  ): ThunkAction<
    void,
    TReduxState,
    unknown,
    TFetchUserStatsAction | TFetchUserStatsSuccessAction | TFetchUserStatsFailureAction
  > =>
  async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.FETCH_USER_STATS,
      })

      const url = `${config.api.backend.url}/stats/me`

      const res = await privateClient.get<TUserStats>(url, { params: request })

      dispatch({
        type: ActionTypes.FETCH_USER_STATS_SUCCESS,
        payload: res.data,
      })
    } catch (err) {
      dispatch({
        type: ActionTypes.FETCH_USER_STATS_FAILURE,
        payload: getReduxErrorObject(err),
      })
    }
  }
