import styled, { css } from 'styled-components/macro'

import { partition } from './partition'
import { useMedia } from 'src/utils/media/useMedia'
import { blickLinks, IFooterLinkType, ringierLinks } from './data'
import { config } from 'src/config'
import { FOOTER_MAX_WIDTH } from './constants'

import { Column } from './Column'
import { FooterLink } from './FooterLink'
import { Collapsible } from './Collapsible'
import { Image } from 'src/components/Image'
import { Spacer } from 'src/components/Spacer'

import blickLogo from 'src/assets/images/logo/blick_logo.svg'
import ringierLogo from 'src/assets/images/logo/ringier_logo.svg'

export const Footer = () => {
  const { isDesktop } = useMedia()
  const columnCount = isDesktop ? 3 : 2
  const blickSpread = partition(blickLinks, columnCount)
  const ringierSpread = partition(ringierLinks, columnCount)
  const blickMinCount = Math.min(...blickSpread.map((i) => i.length))
  const ringierMinCount = Math.min(...ringierSpread.map((i) => i.length))

  const renderColumns = (spread: IFooterLinkType[][], keyPrefix: string, minCount: number) =>
    spread.map((column, pos) => (
      <Column key={`footer-${keyPrefix}-col-${pos}`}>
        {column.map((link, linkPos) => (
          <div key={`footer-${keyPrefix}-link-${pos}-${linkPos}`}>
            {link.override ? (
              <FooterLink onClick={link.override} key={`footer-${keyPrefix}-link-${pos}-${linkPos}`}>
                {link.label}
              </FooterLink>
            ) : (
              <FooterLink href={link.url} key={`footer-${keyPrefix}-link-${pos}-${linkPos}`}>
                {link.label}
              </FooterLink>
            )}
            {linkPos < minCount && <Separator />}
          </div>
        ))}
      </Column>
    ))

  return (
    <Wrapper>
      {!isDesktop && <MobileFascinator />}
      <FooterSection>
        <LogoWrapper>
          <a href={config.externalLinks.blickUrl} target="_blank" rel="noreferrer" aria-label="Blick Logo">
            <Logo src={blickLogo} alt="Blick Logo" />
          </a>
        </LogoWrapper>
        <Content>
          <ContentTitle>Blick.ch - das Schweizer News-Portal © Blick.ch {new Date().getFullYear()}.</ContentTitle>
          <ColumnWrapper>{renderColumns(blickSpread, 'blick', blickMinCount)}</ColumnWrapper>
        </Content>
      </FooterSection>
      <Spacer v size={18} />
      <Collapsible title={'Mehr von Ringier AG'} closedByDefault={true}>
        <FooterSection>
          <LogoWrapper>
            <a href={config.externalLinks.ringierUrl} target="_blank" rel="noreferrer" aria-label="Ringier Logo">
              <Logo src={ringierLogo} alt="Ringier Logo" />
            </a>
          </LogoWrapper>
          <Content>
            <ColumnWrapper>{renderColumns(ringierSpread, 'ringier', ringierMinCount)}</ColumnWrapper>
          </Content>
        </FooterSection>
      </Collapsible>
    </Wrapper>
  )
}

const contentWidthStyle = css`
  margin: 0 auto;
  width: ${FOOTER_MAX_WIDTH}px;
  max-width: ${FOOTER_MAX_WIDTH}px;
  box-sizing: border-box;

  ${({ theme }) => theme.media.isMobile} {
    max-width: 100%;
    padding: 0 16px;
  }
`

const Wrapper = styled.div``

const MobileFascinator = styled.div`
  width: 100%;
  height: 10px;

  background: ${({ theme }) => theme.colors.blick.grey10};
`

const ContentTitle = styled.div`
  margin-bottom: 24px;

  font-family: ${({ theme }) => theme.fonts.sourceSansPro};
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
`

const Logo = styled(Image)`
  width: 144px;
  height: auto;

  ${({ theme }) => theme.media.isMobile} {
    width: 120px;
    margin-bottom: 25px;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`

const LogoWrapper = styled.div`
  width: 216px;
`

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: row;
`

const Separator = styled.div`
  height: 1px;
  width: 50px;
  background-color: ${({ theme }) => theme.colors.blick.grey20};
`

const FooterSection = styled.div`
  ${contentWidthStyle}
  display: flex;
  flex-direction: row;

  margin-top: 35px;

  ${({ theme }) => theme.media.isMobile} {
    flex-direction: column;
  }
`
