import { TDefaultReduxState } from 'src/redux/types'

export const getReduxFetchStates = <T extends TDefaultReduxState>(
  state: T,
  action: string,
): Pick<T, 'loading' | 'error' | 'resolved'> => {
  return {
    loading: {
      ...state.loading,
      [action]: true,
    },
    error: {
      ...state.error,
      [action]: null,
    },
    resolved: {
      ...state.resolved,
      [action]: false,
    },
  }
}

export const getReduxSuccessStates = <T extends TDefaultReduxState>(
  state: T,
  action: string,
): Pick<T, 'loading' | 'resolved'> => {
  return {
    loading: {
      ...state.loading,
      [action]: false,
    },
    resolved: {
      ...state.resolved,
      [action]: true,
    },
  }
}

export const getReduxFailureStates = <T extends TDefaultReduxState>(
  state: T,
  action: string,
  error: AnyObject,
): Pick<T, 'loading' | 'error' | 'resolved'> => {
  return {
    loading: {
      ...state.loading,
      [action]: false,
    },
    error: {
      ...state.error,
      [action]: error,
    },
    resolved: {
      ...state.resolved,
      [action]: false,
    },
  }
}
