import dayjs from 'dayjs'
import { config } from 'src/config'
import './initDates'

export const getNow = (): dayjs.Dayjs => {
  if (!config.isDev) {
    return dayjs.tz(undefined)
  }

  const devTimeShift = parseInt(window.sessionStorage.getItem('dev-time-shift') || '0')
  const devNow = dayjs(Date.now() + devTimeShift).tz()

  return devNow
}
