export enum ActionTypes {
  FETCH_PRODUCT = '@PRODUCT/FETCH_PRODUCT',
  FETCH_PRODUCT_SUCCESS = '@PRODUCT/FETCH_PRODUCT_SUCCESS',
  FETCH_PRODUCT_FAILURE = '@PRODUCT/FETCH_PRODUCT_FAILURE',

  FETCH_PRODUCT_STATS = '@PRODUCT/FETCH_PRODUCT_STATS',
  FETCH_PRODUCT_STATS_SUCCESS = '@PRODUCT/FETCH_PRODUCT_STATS_SUCCESS',
  FETCH_PRODUCT_STATS_FAILURE = '@PRODUCT/FETCH_PRODUCT_STATS_FAILURE',

  PLAY_GAME = '@PRODUCT/PLAY_GAME',
  PLAY_GAME_SUCCESS = '@PRODUCT/PLAY_GAME_SUCCESS',
  PLAY_GAME_FAILURE = '@PRODUCT/PLAY_GAME_FAILURE',
  PLAY_GAME_RESET = '@PRODUCT/PLAY_GAME_RESET',
}
