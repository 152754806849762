import styled, { keyframes } from 'styled-components/macro'
import { useEffect, useState } from 'react'

import {
  SLOT_MACHINE_WIDTH_DESKTOP,
  SLOT_MACHINE_HEIGHT_DESKTOP,
  TOP_PANEL_WIDTH_DESKTOP,
  TOP_PANEL_HEIGHT_DESKTOP,
  TOP_PANEL_WIDTH_MOBILE,
  TOP_PANEL_HEIGHT_MOBILE,
  SLOT_MACHINE_WIDTH_MOBILE,
  SLOT_MACHINE_HEIGHT_MOBILE,
  HANDLER_WIDTH_DESKTOP,
  HANDLER_HEIGHT_DESKTOP,
  HANDLER_WIDTH_MOBILE,
  HANDLER_HEIGHT_MOBILE,
  HANDLER_ANIMATION_DURATION,
  SIDE_PANEL_WIDTH_DESKTOP,
  SIDE_PANEL_WIDTH_MOBILE,
} from './constants'
import { PlayState } from 'src/enums/slotMachine'

import slotMachineWrapBg from 'src/assets/images/slot_machine_wrap.svg'
import slotMachineWrapMobileBg from 'src/assets/images/slot_machine_wrap_mobile.svg'
import slotMachineWrapTopBg from 'src/assets/images/slot_machine_wrap_top.svg'
import slotMachineHandlerBg from 'src/assets/images/slot_machine_handler.svg'
import { useSelector } from 'react-redux'
import { getIsLoggedIn } from 'src/api/auth/selectors'
import { getProductUserConfig } from 'src/api/product/selectors'

type Props = {
  children: React.ReactElement | React.ReactElement[]
  isPlayRunning: boolean
  playState: PlayState | null
  playsLeft: number | undefined
  onPlay: () => void
  text?: string
}

export const ReelsWrap = ({ children, isPlayRunning, playState, playsLeft, onPlay, text }: Props) => {
  const isLoggedIn = useSelector(getIsLoggedIn)
  const productUserConfig = useSelector(getProductUserConfig)
  const [isHandlerAnimationRunning, setHandlerAnimationRunning] = useState(false)

  const isPlayDisabled = isPlayRunning || (isLoggedIn && !playsLeft) || !!productUserConfig?.didAlreadyWin

  useEffect(() => {
    if (isPlayRunning) {
      setHandlerAnimationRunning(true)
      setTimeout(() => {
        setHandlerAnimationRunning(false)
      }, HANDLER_ANIMATION_DURATION)
    }
  }, [isPlayRunning])

  const getTopPanelText = () => {
    if (playState === PlayState.Default) {
      return (
        <TopPanelText $isMultiLined={!text}>
          {text ? (
            text
          ) : (
            <>
              Mach mit!
              <br /> Das Spiel läuft noch:
            </>
          )}
        </TopPanelText>
      )
    }
    return null
  }

  return (
    <Wrap>
      <TopPanel>{getTopPanelText()}</TopPanel>
      <Handler
        className={isHandlerAnimationRunning ? 'animated' : ''}
        $isPlayDisabled={isPlayDisabled || playState === PlayState.DidWin}
        onClick={onPlay}
      />
      {children}
    </Wrap>
  )
}

const handleAnimation = keyframes`
  0% { 
    transform: rotateX(0);
  }
  50% { 
    transform: rotateX(-180deg);
   }
  100% { 
    transform: rotateX(0);
   }
`

const Wrap = styled.div`
  position: relative;
  padding-left: ${SIDE_PANEL_WIDTH_DESKTOP}px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  margin: 0 auto;
  width: ${SLOT_MACHINE_WIDTH_DESKTOP}px;
  height: ${SLOT_MACHINE_HEIGHT_DESKTOP}px;
  background-image: url(${slotMachineWrapBg});
  ${({ theme }) => theme.media.isMobile} {
    padding-left: ${SIDE_PANEL_WIDTH_MOBILE}px;
    width: ${SLOT_MACHINE_WIDTH_MOBILE}px;
    height: ${SLOT_MACHINE_HEIGHT_MOBILE}px;
    background-image: url(${slotMachineWrapMobileBg});
  }
`

const TopPanel = styled.span`
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(calc(-50% + ${SIDE_PANEL_WIDTH_DESKTOP / 2}px), -50%);
  width: ${TOP_PANEL_WIDTH_DESKTOP}px;
  height: ${TOP_PANEL_HEIGHT_DESKTOP}px;
  background-image: url(${slotMachineWrapTopBg});
  background-size: 100%;
  ${({ theme }) => theme.media.isMobile} {
    transform: translate(calc(-50% + ${SIDE_PANEL_WIDTH_MOBILE / 2}px), -50%);
    width: ${TOP_PANEL_WIDTH_MOBILE}px;
    height: ${TOP_PANEL_HEIGHT_MOBILE}px;
  }
`

const TopPanelText = styled.span<{ $isMultiLined?: boolean }>`
  width: 100%;
  display: block;
  position: absolute;
  top: ${({ $isMultiLined }) => ($isMultiLined ? '8px' : '100%')};
  left: 50%;
  transform: ${({ $isMultiLined }) => ($isMultiLined ? 'translate(-50%,0)' : 'translate(-50%,-50%)')};
  font-size: 22px;
  line-height: 25px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.blick.brand70};
  text-align: center;
  ${({ theme }) => theme.media.isMobile} {
    top: ${({ $isMultiLined }) => ($isMultiLined ? '4px' : '100%')};
    font-size: 14px;
    line-height: 20px;
  }
`

const Handler = styled.span<{ $isPlayDisabled: boolean }>`
  position: absolute;
  top: calc(-50% + 24px);
  left: calc(100% - 14px);
  display: block;
  width: ${HANDLER_WIDTH_DESKTOP}px;
  height: ${HANDLER_HEIGHT_DESKTOP}px;
  background-image: url(${slotMachineHandlerBg});
  background-size: cover;
  transform-origin: 0 calc(100% - 48px);
  transform-style: preserve-3d;
  perspective: 1000px;
  cursor: pointer;
  pointer-events: ${({ $isPlayDisabled }) => ($isPlayDisabled ? 'none' : 'all')};
  ${({ theme }) => theme.media.isMobile} {
    width: ${HANDLER_WIDTH_MOBILE}px;
    height: ${HANDLER_HEIGHT_MOBILE}px;
    left: calc(100% - 8px);
  }
  &.animated {
    animation: ${handleAnimation} ${HANDLER_ANIMATION_DURATION}ms 1 ease-in-out;
  }
`
