import styled, { css } from 'styled-components/macro'

type Props = {
  color?: string
  noMargin?: boolean
}

export const HorisontalRule = (props: Props) => {
  return <StyledLine noMargin={props.noMargin} color={props.color} />
}

const StyledLine = styled.hr<Props>`
  width: 100%;
  border: 0;
  border-top: 1px solid ${({ theme, color }) => color || theme.colors.blick.grey60};
  ${({ noMargin }: Props) =>
    noMargin &&
    css`
      margin: 0;
    `}
`
