import { Spacer } from 'src/components/Spacer'
import { StarsLeftText, StarsRightText, StarsRow, StarsWrapper } from 'src/containers/Feedback/Stars'
import { Modal, ModalButtonsWrapper, ModalText, ModalTitle } from 'src/components/modal'
import styled, { useTheme } from 'styled-components/macro'
import { StarIcon } from './StarIcon'
import { useEffect, useState } from 'react'
import { TextArea, TextFormButton } from './TextForm'
import { Slider, TSliderSettings } from 'src/components/Slider'
import Swiper from 'swiper'
import { useMedia } from 'src/utils/media/useMedia'
import { useDispatch } from 'react-redux'
import { postDialog, postFeedback } from 'src/api/feedback/actions'
import { TFeedback } from 'src/api/feedback/types'
import { fetchProduct } from 'src/api/product/actions'
import confettiExplosion from './FeedbackAnimation/confetti_explosion.json'
import Lottie from 'lottie-react'
import { trackFeedbackClick } from 'src/tracking/gtm/actions'

type Props = ReactModal.Props & {
  productId: string
  onRequestClose?: () => void
  onClose: () => void
  fullWidth?: boolean
  campaignId: number
  feedback: TFeedback
}

const STARS_COUNT = 5
const ANIMATION_DURATION = 1800

export const FeedbackModal = ({ isOpen, onClose, style, campaignId, productId }: Props) => {
  const dispatch = useDispatch()

  const theme = useTheme()
  const { isMobile } = useMedia()
  const [starSelectedIndex, setStarSelectedIndex] = useState(-1)
  const [starHoveredIndex, setStarHoveredIndex] = useState(-1)
  const [feedbackMessage, setFeedbackMessage] = useState('')
  const [feedbackPosted, setFeedbackPosted] = useState(false)
  const [showAnimation, setShowAnimation] = useState(false)
  const [slider, setSlider] = useState<Swiper>()

  const getStarColor = () => {
    if (starHoveredIndex > -1) {
      return theme.colors.blick.grey80
    }

    if (starSelectedIndex > -1) {
      return theme.colors.blick.grey100
    }
  }

  const submitFeedback = () => {
    trackFeedbackClick('Feedback schicken')
    slider?.slideNext()
    setShowAnimation(true)
    const payload = { campaignId, message: feedbackMessage, rating: starSelectedIndex + 1 }
    dispatch(postFeedback(payload))
    setFeedbackPosted(true)
  }

  useEffect(() => {
    if (slider?.update) {
      slider?.update()
    }
  })

  useEffect(() => {
    if (showAnimation) {
      setTimeout(() => {
        setShowAnimation(false)
      }, ANIMATION_DURATION)
    }
  }, [showAnimation])

  const skipFeedback = () => {
    trackFeedbackClick('Vielleicht später')
    const payload = { campaignId, skipped: true, doNotAskAgain: false }
    dispatch(postDialog(payload))
    onClose()
  }

  const onRequestClose = () => {
    if (feedbackPosted) {
      setFeedbackPosted(false)
      dispatch(fetchProduct({ productId }))
    } else {
      const payload = { campaignId, skipped: false, doNotAskAgain: true }
      dispatch(postDialog(payload))
    }
    onClose()
  }

  const sliderSettings: TSliderSettings = {
    slidesPerView: 1,
    autoHeight: true,
    autoplay: false,
    allowTouchMove: false,
    observer: true,
  }

  const onModalRequestClose = () => {
    trackFeedbackClick('Close')
    onRequestClose()
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} style={style} onRequestClose={onModalRequestClose}>
      <Slider settings={sliderSettings} onInit={(sliderInstance) => setSlider(sliderInstance)}>
        <>
          <ModalTitle $align="center">Jetzt bist du dran! Hol dir einen Extradreh.</ModalTitle>
          <Spacer size={isMobile ? 16 : 24} v />
          <ModalText $align="center">Teile uns deine Meinung mit. Wie gefällt dir Blick Jackpot?</ModalText>
          <Spacer size={isMobile ? 16 : 24} v />
          <StarsBlockWrapper>
            <StarsWrapper>
              <StarsRow>
                {[...new Array(STARS_COUNT)].map((item, i) => {
                  return (
                    <IconWrapper
                      key={i}
                      onClick={() => {
                        setStarSelectedIndex(i)
                      }}
                      onMouseEnter={() => setStarHoveredIndex(i)}
                      onMouseLeave={() => setStarHoveredIndex(-1)}
                    >
                      <StarIcon
                        isFilled={i <= starSelectedIndex || i <= starHoveredIndex}
                        borderColor={theme.colors.blick.grey100}
                        fillColor={getStarColor()}
                      />
                    </IconWrapper>
                  )
                })}
              </StarsRow>
              <StarsRow>
                <StarsLeftText>Nicht mein Ding</StarsLeftText>
                <StarsRightText>Sehr cool</StarsRightText>
              </StarsRow>
            </StarsWrapper>
          </StarsBlockWrapper>
          <StyledTextArea
            $isVisible={starSelectedIndex > -1}
            placeholder="Schreibe dein Feedback (optional)"
            onChange={(e) => setFeedbackMessage(e.target.value)}
          ></StyledTextArea>
          <ModalButtonsWrapper align="center">
            <TextFormButton
              componentTheme="black"
              onClick={starSelectedIndex > -1 ? submitFeedback : skipFeedback}
              height="40px"
              fullWidth={isMobile}
            >
              {starSelectedIndex > -1 ? 'Feedback schicken' : 'Vielleicht später'}
            </TextFormButton>
          </ModalButtonsWrapper>
        </>
        <>
          {showAnimation && (
            <AnimationWrapper>
              <Lottie animationData={confettiExplosion} autoplay loop={false} />
            </AnimationWrapper>
          )}
          <ModalTitle $align="center">Vielen Dank für dein Feedback!</ModalTitle>
          <Spacer size={isMobile ? 16 : 24} v />
          <ModalText $align="center">Als Dankeschön erhältst du</ModalText>
          <Spacer size={isMobile ? 16 : 24} v />
          <StyledModalText $align="center">einen Extradreh</StyledModalText>
          <Spacer size={isMobile ? 16 : 24} v />
          <ModalButtonsWrapper align="center">
            <TextFormButton componentTheme="black" onClick={onRequestClose} height="40px" fullWidth={isMobile}>
              zum extradreh
            </TextFormButton>
          </ModalButtonsWrapper>
        </>
      </Slider>
    </Modal>
  )
}

const StarsBlockWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 8px;
`

const StyledModalText = styled(ModalText)`
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  color: ${({ theme }) => theme.colors.states.success};
`

const StyledTextArea = styled(TextArea)<{ $isVisible: boolean }>`
  ${({ $isVisible }) =>
    !$isVisible &&
    `
    margin: 0;
    padding: 0;
height: 0px;
border-color: transparent;`}
`

const AnimationWrapper = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
`

export const IconWrapper = styled.div`
  cursor: pointer;
`
