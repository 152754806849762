import { TCampaign, TCampaignSymbol, TGameResult, TProductCurrentUser } from 'src/api/product/types'
import { PlayState } from 'src/enums/slotMachine'
import { getRandomNumber } from 'src/utils/common'
import { DEFAULT_PLAYS_PER_DAY } from 'src/containers/SlotMachine/constants'
import { TTimerSymbol } from 'src/containers/SlotMachine/types'
import { getId } from './getId'
import { isLastDay } from 'src/utils/date/relativeDate'

export const getFactor = (idx: number) => {
  return 1 + Math.pow(idx / 2, 2)
}

export const getSymbolsUrls = (symbols: TCampaignSymbol[]) => {
  return symbols.map((symbol) => symbol.imageUrl)
}

export const getReelNextSymbols = (nextSymbol: TCampaignSymbol | TTimerSymbol, symbols: TCampaignSymbol[]) => {
  return [symbols[getRandomNumber(symbols.length)], nextSymbol, symbols[getRandomNumber(symbols.length)]]
}

export const getSymbolsFromResult = (symbolsOutcome: number[], symbols: TCampaignSymbol[]) => {
  return symbolsOutcome.map((symbolOutcome) => {
    const symbol = symbols.find((symbol) => symbol.id === symbolOutcome)
    return symbol || { id: 0, imageUrl: '' }
  })
}

export const getPlayState = (
  gameResult: TGameResult | null,
  currentUserConfig: TProductCurrentUser | undefined | null,
  currentCampaign: TCampaign,
): PlayState => {
  if (currentUserConfig && gameResult?.didWin) {
    return PlayState.DidWin
  }
  if (currentUserConfig && currentUserConfig.playsLeft > 1 && currentUserConfig.playsLeft < DEFAULT_PLAYS_PER_DAY) {
    return PlayState.PlayAgain
  }
  if (currentUserConfig && currentUserConfig.playsLeft === 1) {
    return PlayState.OnePlayLeft
  }
  if (currentUserConfig && currentUserConfig.playsLeft === 0) {
    if (isLastDay(currentCampaign)) {
      return PlayState.NoPlaysLeftInCampaign
    } else {
      return PlayState.NoPlaysLeft
    }
  }

  if (currentUserConfig && currentUserConfig.playsLeft === 0) {
    return PlayState.NoPlaysLeft
  }
  return PlayState.Default
}

export const isCampaignSymbol = (symbol: TCampaignSymbol | TTimerSymbol): symbol is TCampaignSymbol => {
  if ('imageUrl' in symbol || 'id' in symbol) {
    return true
  }
  return false
}

export const getSymbolsWithElementId = (symbols: (TCampaignSymbol | TTimerSymbol)[]) => {
  return symbols.map((symbol) => ({
    ...symbol,
    elementId: getId(),
  }))
}
