import styled from 'styled-components/macro'

type Props = {
  $align?: 'left' | 'center' | 'right'
}

export const ModalTitle = styled.div<Props>`
  margin: 0;
  padding: 0 24px 0 0;
  text-align: ${({ $align }) => $align || 'left'};
  word-wrap: break-word;
  font-size: 22px;
  line-height: 25px;
  font-weight: 600;
  ${({ theme }) => theme.media.isMobile} {
    text-align: left;
  }
`
