import { Button } from 'src/components/buttons/Button'
import styled from 'styled-components/macro'

export const TextArea = styled.textarea`
  width: 100%;
  border-radius: 8px;
  border: 0;
  resize: none;
  height: 91px;
  margin: 16px 0 24px 0;
  padding: 16px;
  box-sizing: border-box;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  font-family: ${({ theme }) => theme.fonts.sourceSansPro};
  color: ${({ theme }) => theme.colors.blick.grey90};
  border: 1px solid ${({ theme }) => theme.colors.blick.grey30};

  &::placeholder {
    color: ${({ theme }) => theme.colors.blick.grey70};
  }

  &:focus {
    border: 1px solid ${({ theme }) => theme.colors.blick.grey90};
    outline: none;
  }
`

export const TextFormButton = styled(Button)`
  padding: 0px 24px;
  ${({ theme }) => theme.media.isMobile} {
    margin: 0 auto;
    display: block;
  }
`
