import styled from 'styled-components/macro'
import ReactModal from 'react-modal'

import { CloseIcon } from 'src/components/CloseIcon'

const getStyle = (width?: string): ReactModal.Styles => ({
  content: getContentStyle(width),
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.4)',
    zIndex: 1000,
  },
})

export const getContentStyle = (width?: string) => {
  return {
    width: 'calc(100% - 32px)',
    maxWidth: width || '560px',
    padding: '',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    border: 'none',
    borderRadius: '8px',
    background: '#FFFFFF',
    boxShadow: '0px 1px 4px 1px rgba(0, 0, 0, 0.25)',
    boxSizing: 'border-box',
  } as React.CSSProperties
}

type Props = ReactModal.Props & {
  onRequestClose?: () => void
  onClose: () => void
  fullWidth?: boolean
  hideCloseButton?: boolean
  isEdit?: boolean
  width?: string
}

export const Modal = ({ children, isOpen, style, hideCloseButton, onRequestClose, onClose, width, ...rest }: Props) => {
  const defaultStyle = getStyle(width)

  const combinedStyles: ReactModal.Styles = {
    content: {
      ...defaultStyle.content,
      ...style?.content,
    },
    overlay: {
      ...defaultStyle.overlay,
      ...style?.overlay,
    },
  }

  const getCloseIconPosition = () => {
    if (!CSS.supports('right', `${style?.content?.padding}`)) {
      return '16px'
    }
    if (style?.content?.padding) {
      return `${style?.content?.padding}`
    }

    if (defaultStyle.content?.padding) {
      return `${defaultStyle.content?.padding}`
    }

    return '16px'
  }

  return (
    <ReactModal
      shouldCloseOnEsc
      shouldCloseOnOverlayClick
      {...rest}
      isOpen={isOpen}
      onRequestClose={onRequestClose || onClose}
      style={combinedStyles}
      appElement={document.getElementById('root') || undefined}
    >
      {!hideCloseButton && <StyledCloseIcon onClick={onRequestClose} size={18} $position={getCloseIconPosition()} />}
      {children}
    </ReactModal>
  )
}

const StyledCloseIcon = styled(CloseIcon)<{ $position: string }>`
  position: absolute;
  right: ${({ $position }) => $position};
  top: ${({ $position }) => $position};
  cursor: pointer;
  z-index: 100;
`
