import { Reducer } from 'redux'

import { TActions, TStatsState } from './types'
import { ActionTypes } from './enums'

import { getReduxFailureStates, getReduxFetchStates, getReduxSuccessStates } from 'src/utils/redux'

export const initialState: TStatsState = {
  data: null,
  loading: {
    fetchStats: false,
  },
  error: {
    fetchStats: null,
  },
  resolved: {
    fetchStats: false,
  },
}

export const reducer: Reducer<TStatsState, TActions> = (state = initialState, action): TStatsState => {
  switch (action.type) {
    case ActionTypes.FETCH_USER_STATS: {
      return {
        ...state,
        ...getReduxFetchStates(state, 'fetchStats'),
      }
    }
    case ActionTypes.FETCH_USER_STATS_SUCCESS: {
      return {
        ...state,
        data: action.payload,
        ...getReduxSuccessStates(state, 'fetchStats'),
      }
    }
    case ActionTypes.FETCH_USER_STATS_FAILURE: {
      return {
        ...state,
        ...getReduxFailureStates(state, 'fetchStats', action.payload),
      }
    }
    default:
      return state
  }
}
