import styled, { css } from 'styled-components/macro'

import { TSlotMachineConfig, TCampaignSymbol } from 'src/api/product/types'

import { Container } from 'src/components/Container'
import { ReelsWrap } from './ReelsWrap'
import { Reel } from './Reel'
import { Image } from 'src/components/Image'
import { Button } from 'src/components/buttons/Button'
import { Description } from './Description'
import { WinningAnimation } from './WinningAnimation/WinningAnimation'

import jackpotLogo from 'src/assets/images/jackpot_logo.svg'
import jackpotBackground from 'src/assets/images/jackpot_bg.svg'
import { ReactComponent as SoundOffIcon } from 'src/assets/images/soundOff_icon.svg'
import { ReactComponent as SoundIcon } from 'src/assets/images/sound_icon.svg'

import { useSlotMachine } from './useSlotMachine'
import { NotificationSubscription } from './NotificationSubscription'
import { PlayState } from 'src/enums/slotMachine'
import { Spacer } from 'src/components/Spacer'
import { useMedia } from 'src/utils/media/useMedia'
import { TFeedback } from 'src/api/feedback/types'

export type TSlotMachineId = 'slot_machine' | 'no_product_slot_machine'

type Props = {
  id?: TSlotMachineId
  config: TSlotMachineConfig | null
  symbols: TCampaignSymbol[]
  productId: string
  reelsText?: string
  playStateOverride?: PlayState | null
  onShowFeedbackModal?: () => void
  feedback?: TFeedback | null
}

export const SlotMachine = ({
  id = 'slot_machine',
  config,
  symbols,
  productId,
  reelsText,
  playStateOverride = null,
  onShowFeedbackModal,
  feedback,
}: Props) => {
  const {
    gameResult,
    reels,
    onPlay,
    isPlayRunning,
    playState,
    slotMachineState,
    currentUserConfig,
    timerSymbols,
    userNickName,
    userEmail,
    isWinningAnimationPlaying,
    setWinningAnimationPlaying,
    innerWrapperRef,
    wrapperHeight,
    title,
    isAudioEnabled,
    onAudioButtonClick,
  } = useSlotMachine(id, config, symbols, productId, feedback, onShowFeedbackModal)

  const { isMobile } = useMedia()
  const getButtonText = () => {
    if (!currentUserConfig) {
      return <>Anmelden und spielen</>
    }
    if (playState === PlayState.DidWin) {
      return <>Herzlichen Glückwunsch</>
    }
    if (playState === PlayState.NoPlaysLeft) {
      return <>Neuer Tag, neues glück</>
    }
    if (playState === PlayState.NoPlaysLeftInCampaign) {
      return <>Leider kein Glück</>
    }
    return (
      <>
        JETZT DREHEN
        <PlayButtonTextSm>
          Noch {currentUserConfig.playsLeft} {currentUserConfig.playsLeft === 1 ? 'Versuch' : 'Versuche'}
        </PlayButtonTextSm>
      </>
    )
  }

  const getButtonTextString = () => {
    if (!currentUserConfig) {
      return 'Anmelden und spielen'
    }
    if (playState === PlayState.DidWin) {
      return 'Herzlichen Glückwunsch'
    }
    if (playState === PlayState.NoPlaysLeft) {
      return 'Neuer Tag, neues glück'
    }
    if (playState === PlayState.NoPlaysLeftInCampaign) {
      return 'Leider kein Glück'
    }
    return `JETZT DREHEN Noch ${currentUserConfig.playsLeft} ${
      currentUserConfig.playsLeft === 1 ? 'Versuch' : 'Versuche'
    }`
  }

  const shouldShowNotificationSubscription = () => {
    return playStateOverride && playStateOverride === PlayState.АlreadyWonOnce
  }

  return (
    <>
      <SectionWrapper height={wrapperHeight}>
        <InnerWrapper ref={innerWrapperRef}>
          <JackpotLogo src={jackpotLogo} />
          <ReelsWrap
            isPlayRunning={isPlayRunning}
            playState={playState}
            playsLeft={currentUserConfig?.playsLeft}
            onPlay={() => onPlay('anyArgumentToMakeFunctionWork', 'lever', 'lever')}
            text={reelsText}
          >
            {reels.map((reel, index) => (
              <Reel
                key={reel.id}
                ref={reel.ref}
                index={index}
                symbols={symbols}
                winnerSymbolId={gameResult?.symbolOutcome[index] ?? null}
                slotMachineState={slotMachineState}
                timerSymbol={timerSymbols ? timerSymbols[index] : null}
              />
            ))}
          </ReelsWrap>
          {shouldShowNotificationSubscription() ? (
            <>
              <Spacer v size={30} />
              <Description
                playState={playStateOverride}
                userNickName={userNickName}
                userEmail={userEmail}
                title={title}
              />
              <NotificationSubscription />
            </>
          ) : playStateOverride === PlayState.NoGame ? (
            <>
              {isMobile ? <Spacer v size={24} /> : <Spacer v size={30} />}
              <Description
                playState={playStateOverride}
                userNickName={userNickName}
                userEmail={userEmail}
                title={title}
              />
            </>
          ) : (
            <>
              <PlayButton
                onClick={() => onPlay('anyArgumentToMakeFunctionWork', 'button', getButtonTextString())}
                disabled={isPlayRunning || currentUserConfig?.playsLeft === 0 || playState === PlayState.DidWin}
                componentTheme="secondary"
              >
                {getButtonText()}
              </PlayButton>
              <Description playState={playState} userNickName={userNickName} userEmail={userEmail} title={title} />
              {playState === PlayState.NoPlaysLeftInCampaign && <NotificationSubscription />}
              {playState !== PlayState.NoPlaysLeftInCampaign && playState !== PlayState.NoPlaysLeft && (
                <SoundIconButton onClick={onAudioButtonClick}>
                  {isAudioEnabled ? <SoundIcon /> : <SoundOffIcon />}
                </SoundIconButton>
              )}
            </>
          )}
        </InnerWrapper>
      </SectionWrapper>
      <WinningAnimation
        isWinningAnimationPlaying={isWinningAnimationPlaying}
        setWinningAnimationPlaying={setWinningAnimationPlaying}
      />
    </>
  )
}

const InnerWrapper = styled(Container)`
  padding: 0 16px;
  ${({ theme }) => theme.media.isMobile} {
    padding: 0 32px 0 16px;
  }
  @media screen and (max-width: 375px) {
    padding: 0 32px 0 4px;
  }
`

const SectionWrapper = styled.div<{ height?: number }>`
  position: relative;
  padding: 40px 0;
  background: url(${jackpotBackground});
  background-position: center;
  background-size: cover;
  height: ${({ height }) => (height ? `${height}px` : 'auto')};
  box-sizing: content-box;
  transition: height 0.1s;

  ${({ theme }) => theme.media.isMobile} {
    padding: 24px 0;
  }
`

const JackpotLogo = styled(Image)`
  display: block;
  width: 270px;
  margin: 0 auto 64px auto;
  min-height: 43px;

  ${({ theme }) => theme.media.isMobile} {
    width: 190px;
    min-height: 30px;
    margin: 0 auto 40px auto;
    transform: translateX(8px);
  }
`

const PlayButton = styled(Button)`
  display: block;
  position: relative;
  left: 50%;
  transform: translate(calc(-50% + 16px), -50%);
  min-width: 320px;
  > span {
    margin-left: 0 !important;
  }

  ${({ theme }) => theme.media.isMobile} {
    transform: translate(calc(-50% + 8px), calc(-50% + 6px));
    min-width: 260px;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 1;
      background: #ffc840;
      border: 2px solid #f4a42f;
      color: ${({ theme }) => theme.colors.blick.food80};
      &:hover {
        opacity: 1;
        background: #ffc840;
        border: 2px solid #f4a42f;
        color: ${({ theme }) => theme.colors.blick.food80};
      }
    `};
`

const PlayButtonTextSm = styled.div`
  font-size: 12px;
  line-height: 14.4px;
`

const SoundIconButton = styled.div`
  position: initial;
  margin-left: calc(100% - 25px);
  margin-top: 8px;
  cursor: pointer;
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  background: linear-gradient(180deg, #313131 0%, #484848 34.9%, #242424 78.12%);
  border-radius: 50%;
  filter: drop-shadow(2px 2px 0px rgba(0, 0, 0, 0.56));
  &:hover {
    background: #1f1f1f;
    filter: drop-shadow(1px 1px 0px rgba(0, 0, 0, 0.56));
  }
  ${({ theme }) => theme.media.isDesktop} {
    position: absolute;
    margin: 0;
    right: 40px;
    bottom: 40px;
    width: 52px;
    height: 52px;
    svg {
      width: 36px;
      height: 36px;
    }
  }
`
