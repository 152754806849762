import styled from 'styled-components/macro'

export const SectionTitle = styled.h2`
  margin: 0;
  font-family: ${({ theme }) => theme.fonts.goodOffcPro};
  font-weight: 950;
  font-size: 36px;
  line-height: 50px;
  letter-spacing: 0.02em;
  color: inherit;

  ${({ theme }) => theme.media.isMobile} {
    font-size: 28px;
    line-height: 36px;
  }
`
