import { sanitizeUrl } from 'src/utils/sanitizeUrl'

export const urlify = (envVar: string | undefined, fallback?: string) => sanitizeUrl(envVar, fallback)

const version = process.env.REACT_APP_VERSION || 'No commit hash found'
const env = process.env.REACT_APP_ENV || process.env.NODE_ENV
const isLocalhost = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1'
const isDev = env !== 'prod' && env !== 'production'
const isProd = env === 'prod' || env === 'production'

if (typeof window !== 'undefined') {
  Object.assign(window, { VERSION: version })
}

export const config = {
  version,
  isLocalhost,
  release: process.env.REACT_APP_SENTRY_RELEASE_NAME || 'localhost',
  isProd,
  isDev,
  selfUrl: process.env.PUBLIC_URL,
  app: {
    rootElementId: 'root',
    env,
  },
  // Service APIs
  api: {
    backend: {
      url: urlify(process.env.REACT_APP_BACKEND_URL, 'https://gameofchance-api.d.trophy.ws.blick.ch/api/v1'),
    },
    onelog: {
      url: urlify(process.env.REACT_APP_ONELOG_URL, 'https://login.s.onelog.ch'),
      profileUrl: urlify(process.env.REACT_APP_ONELOG_PROFILE_URL, 'https://profile.s.onelog.ch/'),
      clientId: process.env.REACT_APP_ONELOG_CLIENT_ID || 'QiQsPyS8NNexn6BGbIdfVAyGaihbDsrP',
      redirectUrl: urlify(process.env.REACT_APP_ONELOG_REDIRECT_URL) || 'https://auth.stg.ws.blick.ch/login',
      logoutUrl: urlify(process.env.REACT_APP_ONELOG_LOGOUT_URL) || 'https://auth.stg.ws.blick.ch/logout',
      metadataUrl: urlify(process.env.REACT_APP_ONELOG_METADATA_URL) || 'https://auth.stg.ws.blick.ch/metadata',
      loginCase: process.env.REACT_APP_ONELOG_LOGIN_CASE || 'jackpot_2023_game',
      scope: process.env.REACT_APP_ONELOG_SCOPE || 'openid email fields admeira:id offline_access',
    },
  },
  externalLinks: {
    blickUrl: 'https://www.blick.ch',
    blickSkiTrophyUrl: 'https://trophy.blick.ch/skitrophy',
    blickSignUpUrl:
      'https://www.blick.ch/gewinnen/blick-gewinnspiel-newsletter-jede-woche-die-neusten-wettbewerbe-und-exklusive-preise-id17772566.html',
    blickSignUpIframe: 'https://link.blick.ch/join/6ma/signup-gewinnspiel&hash=733b8278110579f712b1bfcfcf8f3e84',
    ringierUrl: 'https://www.ringier.ch',
    adminUrl: 'https://admin.s.jackpot.blick.ch',
  },
}
