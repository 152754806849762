import styled, { css } from 'styled-components/macro'

type Props = {
  $centered?: boolean
}

export const DrawerTitle = styled.div<Props>`
  font-family: ${({ theme }) => theme.fonts.sourceSansPro};
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  ${({ $centered }) =>
    $centered &&
    css`
      margin-left: auto;
      margin-right: auto;
    `}
  ${({ theme }) => theme.media.isMobile} {
    margin: 0 auto;
  }
`
