export enum ActionTypes {
  GET_FEEDBACK = '@FEEDBACK/GET_FEEDBACK',
  GET_FEEDBACK_SUCCESS = '@FEEDBACK/GET_FEEDBACK_SUCCESS',
  GET_FEEDBACK_FAILURE = '@FEEDBACK/GET_FEEDBACK_FAILURE',

  POST_FEEDBACK = '@FEEDBACK/POST_FEEDBACK',
  POST_FEEDBACK_SUCCESS = '@FEEDBACK/POST_FEEDBACK_SUCCESS',
  POST_FEEDBACK_FAILURE = '@FEEDBACK/POST_FEEDBACK_FAILURE',

  SET_FORCE_SHOW_FEEDBACK = '@FEEDBACK/SET_FORCE_SHOW_FEEDBACK',
  CLEAR_FORCE_SHOW_FEEDBACK = '@FEEDBACK/CLEAR_FORCE_SHOW_FEEDBACK',
}
