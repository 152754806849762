import { useState } from 'react'
import styled, { css } from 'styled-components/macro'

import { config } from 'src/config'
import { privateClient } from 'src/utils/clients'

import { Button } from 'src/components/buttons/Button'
import { Spacer } from 'src/components/Spacer'
import { useDispatch, useSelector } from 'react-redux'
import { getForceWinPrizeId, getPrizes } from 'src/api/prizes/selectors'
import { TPrizes } from 'src/api/prizes/types'
import { clearForceWinPrizeId, setForceWinPrizeId } from 'src/api/prizes/actions'
import { setForceShowFeedback } from 'src/api/feedback/actions'
import { ExternalLinkButton } from 'src/components/links/ExternalLinkButton'
import { getProductFromPath } from 'src/utils/auth'

const getSelectValues = (prizes: TPrizes | null) => {
  if (!Array.isArray(prizes)) {
    return []
  }
  return [{ value: -1, label: 'None' }, ...prizes.map(({ id, name }) => ({ value: id, label: name }))]
}

export const DevToolBar = () => {
  const productId = getProductFromPath()
  const dispatch = useDispatch()
  const [isOpen, setIsOpen] = useState(false)
  const [isActive, setActive] = useState(true)
  const prizes = useSelector(getPrizes)
  const forceWinPrizeId = useSelector(getForceWinPrizeId)

  const selectValues = getSelectValues(prizes)

  const onClearPlays = async () => {
    try {
      await privateClient.delete(`${config.api.backend.url}/plays`)

      if (typeof window !== 'undefined') {
        window.location.reload()
      }
    } catch (err) {
      console.error('Error during reseting plays', err)
    }
  }

  const onPrizeSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const prizeId = e.target.value
    if (prizeId === '-1') {
      dispatch(clearForceWinPrizeId())
    } else {
      dispatch(setForceWinPrizeId(prizeId))
    }
  }

  if (!isOpen) {
    return <FixedButton onClick={() => setIsOpen(true)}>Open Toolbar</FixedButton>
  }

  if (!isActive) {
    return null
  }

  return (
    <Wrapper>
      <Title>Developer Toolbar</Title>

      <SubTitle>Reset the state of features on the client and the server.</SubTitle>

      <InlineButtons>
        <Button componentTheme="black" onClick={onClearPlays} height="40px">
          Clear plays
        </Button>
      </InlineButtons>
      <Spacer v size={20} />

      <SubTitle>Remove the toolbar until the next page reload</SubTitle>
      <Button
        height="40px"
        componentTheme="black"
        onClick={() => {
          setActive(false)
        }}
      >
        Remove toolbar
      </Button>
      <Spacer v size={20} />

      <SubTitle>Force feedback modal to show after spin</SubTitle>
      <Button
        height="40px"
        componentTheme="black"
        onClick={() => {
          dispatch(setForceShowFeedback())
        }}
      >
        force show feedback
      </Button>

      {selectValues.length > 0 && (
        <>
          <Spacer v size={20} />
          <SubTitle>Select prize to force win</SubTitle>
          <select onChange={onPrizeSelectChange} value={forceWinPrizeId}>
            {selectValues.map(({ value, label }) => (
              <option key={`option_${value}`} value={value}>
                {label}
              </option>
            ))}
          </select>
        </>
      )}
      <Spacer v size={20} />

      <ExternalLinkButton height="40px" componentTheme="black" href={`${config.externalLinks.adminUrl}/${productId}`}>
        Open product admin
      </ExternalLinkButton>

      <FixedButtonInline onClick={() => setIsOpen(false)}>Close Toolbar</FixedButtonInline>
    </Wrapper>
  )
}

const fixedStyle = css`
  position: fixed;
  z-index: ${({ theme }) => theme.zIndex.aboveAll};
  bottom: 10px;
  right: 10px;
`

const shadowStyle = css`
  box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.4);
`

const Title = styled.div`
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.4px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 24px;

  ${({ theme }) => theme.media.isDesktop} {
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.4px;

    margin-bottom: 32px;
  }
`

const SubTitle = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  margin-bottom: 1rem;
`

const FixedButton = styled(Button)`
  ${fixedStyle}
  ${shadowStyle}

  cursor: pointer;
  border: none;

  color: white;
  background: orange;
`

const FixedButtonInline = styled(FixedButton)`
  border: none;
  box-shadow: none;
  background: orange;
`

const Wrapper = styled.div`
  ${fixedStyle}
  ${shadowStyle}
  top: 10px;
  bottom: 70px;
  overflow-y: auto;
  width: 250px;
  max-width: 60%;
  padding: 20px;

  color: white;
  background-color: orange;
  border-radius: 15px;
  ${({ theme }) => theme.media.isMobile} {
    bottom: 60px;
  }
`

const InlineButtons = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 6px;
  > * {
    margin-bottom: 6px;
  }
`
