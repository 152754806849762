export const getArrayFromNumber = (number: number) => {
  return [...Array(number).keys()]
}

export const getRandomNumber = (max: number) => Math.floor(Math.random() * max)

export const firstLetter = (s?: string): string => {
  if (!s) return ''

  // There exists polyfill for Intl.Segmenter but it's large. A workaround for old browsers is enough.
  if (!Intl.Segmenter) {
    const [unicodeChar] = s
    return unicodeChar || ''
  }

  const [unicodeSegment] = new Intl.Segmenter().segment(s)
  return unicodeSegment?.segment || ''
}
