import { ThunkAction } from 'redux-thunk'
import { TReduxState } from 'src/redux/store'

import { ActionTypes } from './enums'
import { getReduxErrorObject, showToastError } from 'src/utils/errors'
import {
  TPrizes,
  TFetchPrizesRequest,
  TFetchPrizesAction,
  TFetchPrizesSuccessActions,
  TFetchPrizesFailureAction,
  TSetForceWinPrizeIdAction,
  TClearForceWinPrizeIdAction,
} from './types'
import { privateClient } from 'src/utils/clients'
import { config } from 'src/config'

export const fetchPrizes =
  (
    request: TFetchPrizesRequest,
  ): ThunkAction<
    void,
    TReduxState,
    unknown,
    TFetchPrizesAction | TFetchPrizesSuccessActions | TFetchPrizesFailureAction
  > =>
  async (dispatch) => {
    const { campaignId } = request

    try {
      dispatch({
        type: ActionTypes.FETCH_PRIZES,
        payload: request,
      })

      const url = `${config.api.backend.url}/campaigns/${campaignId}/prizes`

      const res = await privateClient.get<TPrizes>(url)

      dispatch({
        type: ActionTypes.FETCH_PRIZES_SUCCESS,
        payload: res.data,
      })
    } catch (err) {
      showToastError('PRIZES LOADIN ISSUE')
      dispatch({
        type: ActionTypes.FETCH_PRIZES_FAILURE,
        payload: getReduxErrorObject(err),
      })
    }
  }

export const setForceWinPrizeId = (id: string): TSetForceWinPrizeIdAction => ({
  type: ActionTypes.SET_FORCE_WIN_PRIZE_ID,
  payload: id,
})

export const clearForceWinPrizeId = (): TClearForceWinPrizeIdAction => ({
  type: ActionTypes.CLEAR_FORCE_WIN_PRIZE_ID,
})
