import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { getUserStatsFetchLoading, getUserStats } from 'src/api/stats/selectors'
import { getCurrentCampaignId } from 'src/api/product/selectors'
import { fetchUserStats } from 'src/api/stats/actions'

import { UserStatsSkeleton } from 'src/components/skeleton/presets/UserStats'
import { Section, SectionRow, SectionKey, SectionValue } from './components'

export const UserDashBoardContent = () => {
  const dispatch = useDispatch()

  const currentCampaignId = useSelector(getCurrentCampaignId)
  const isUserStatsFetchLoading = useSelector(getUserStatsFetchLoading)
  const userStats = useSelector(getUserStats)

  useEffect(() => {
    if (currentCampaignId) {
      dispatch(fetchUserStats({ campaignId: currentCampaignId }))
    }
  }, [currentCampaignId, dispatch])

  if (isUserStatsFetchLoading) {
    return <UserStatsSkeleton />
  }

  if (userStats) {
    return (
      <Section $noBorder>
        <SectionRow>
          <SectionKey>So lief dein letztes Jackpot-Spiel</SectionKey>
        </SectionRow>
        <SectionRow>
          <SectionKey>{userStats.totalPlays} mal</SectionKey>
          <SectionValue>Jackpot gespielt</SectionValue>
        </SectionRow>
        <SectionRow>
          <SectionKey>{userStats.daysInARow} Tage</SectionKey>
          <SectionValue>in Folge das Glück herausgefordert</SectionValue>
        </SectionRow>
      </Section>
    )
  }

  return <Section>No stats available</Section>
}
