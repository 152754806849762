export const colors = {
  shades: {
    white: '#fff',
  },
  blick: {
    brand: '#e20000',
    brandBg: '#c00500',
    brand5: '#fff3f2',
    brand10: '#FED1CD',
    brand55: '#e20000',
    brand60: '#c00500',
    brand70: '#9e0900',
    brand100: '#170400',
    green: '#9fe72c',
    greenBg: '#88dc24',
    greenAlt: '#68b83a',
    red: '#f00',
    redBg: '#4d0707',
    redAlt: '#c20000',
    sport: '#46d079',
    sport5: '#EBF5F0',
    sport10: '#CCF3D9',
    sport55: '#62a01a',
    sport60: '#3b8910',
    sport65: '#1D710B',
    sportBg: '#163327',
    people: '#e26ac6',
    peopleBg: '#3b1432',
    life: '#5db4ff',
    lifeBg: '#0a193b',
    sponsor: '#ffb120',
    sponsorBg: '#4a3610',
    blueGrey: '#8395aa',
    blueGreyAlt: '#616C77',
    bluePrize: '#192A6B',
    grey10: '#F8F8F8',
    grey15: '#f3f3f3',
    grey20: '#eeeeee',
    grey30: '#A0A3A6',
    grey50: '#6A6E71',
    grey55: '#46505a',
    grey60: '#A9A9A9',
    grey70: '#8e8e90',
    grey75: '#767676',
    grey80: '#545454',
    grey90: '#3d3d3d',
    grey100: '#1f1f1f',
    white: '#fff',
    food80: '#B84700',
  },
  states: {
    error: '#ff0024',
    focus: '#2460C5',
    success: '#62a01a',
    warning: '#F59C00',
  },
}
