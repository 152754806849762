import { useCallback, useEffect, useRef, memo } from 'react'
import styled from 'styled-components/macro'
import Lottie, { LottieRefCurrentProps } from 'lottie-react'

import animationData from './confetti_animation.json'

type Props = {
  isWinningAnimationPlaying: boolean
  setWinningAnimationPlaying: (value: boolean) => void
}

export const WinningAnimation = memo(({ isWinningAnimationPlaying, setWinningAnimationPlaying }: Props) => {
  const animationRef = useRef<LottieRefCurrentProps>(null)

  const onPlayAnimation = useCallback(() => {
    if (animationRef && animationRef.current) {
      animationRef.current.play()
    }
  }, [animationRef])

  const onStopAnimation = useCallback(() => {
    if (animationRef && animationRef.current) {
      animationRef.current?.stop()

      setWinningAnimationPlaying(false)
    }
  }, [animationRef, setWinningAnimationPlaying])

  useEffect(() => {
    if (isWinningAnimationPlaying) {
      onPlayAnimation()
    }
  }, [isWinningAnimationPlaying, onPlayAnimation])

  return (
    <AnimationWrap $isAnimationPlaying={isWinningAnimationPlaying}>
      <Lottie
        lottieRef={animationRef}
        animationData={animationData}
        loop={false}
        autoplay={false}
        onComplete={() => onStopAnimation()}
      />
    </AnimationWrap>
  )
})

WinningAnimation.displayName = 'WinningAnimation'

const AnimationWrap = styled.div<{ $isAnimationPlaying: boolean }>`
  display: ${({ $isAnimationPlaying }) => ($isAnimationPlaying ? 'block' : 'none')};
  position: fixed;
  top: -20vh;
  left: 0;
  width: 100%;
  height: 80vh;
  ${({ theme }) => theme.media.isMobile} {
    top: 0;
    height: 100vh;
  }
`
