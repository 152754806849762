import { useMemo, createRef } from 'react'

import { getId } from './getId'
import { getArrayFromNumber } from 'src/utils/common'

import { TReelAPI } from 'src/containers/SlotMachine/Reel'

export const useReels = (numberOfSpinners: number) =>
  useMemo(
    () =>
      getArrayFromNumber(numberOfSpinners).map(() => ({
        ref: createRef<TReelAPI>(),
        id: getId(),
      })),
    [numberOfSpinners],
  )
