import { useRef, useLayoutEffect, MutableRefObject } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { LoginMethods } from 'src/enums/auth'
import { localStorageGetParsedItem, localStorageRemoveItem, localStorageSetItem } from 'src/utils/localStorage'
import { getProductFromPath } from 'src/utils/auth'

import {
  getIsAuthResolved,
  getIsLoggedIn,
  getHasUserNickname,
  getIsAgreementsSigned,
  getHasUserFullAddress,
} from './selectors'
import { login } from './actions'

export type GameOrLoginType = LoginMethods.SLOT_MACHINE

//
//
export const useProtectedHref = (method: GameOrLoginType, href: string) => {
  const isUserReadyToPlay = useIsLoggedInToGame()

  if (isUserReadyToPlay) {
    return href
  }

  const productId = getProductFromPath()

  return `/${productId}/login_redirect?${new URLSearchParams(Object.entries({ url: href, method }))}`
}

//
//
export const useProtectedCallback = <T extends unknown[]>(
  method: GameOrLoginType,
  // Some unique string for a component on a current page
  // Can be just a name of a component or an empty string if
  // there's only one component uses this hook on a page
  id: string,
  protectedCallback: (...payload: T) => void,
  readyToCall = true,
  // in cases where the payload is long store it and get it from localStorage
  localStorage = false,
  // callback to restore data outside of protectedCallback
  restoreDataCallback: ((...payload: T) => void) | null = null,
  baseReturnUrl = window.location.href,
): ((...payload: T) => void) => {
  useUniqueID(id)

  const dispatch = useDispatch()
  const isUserReadyToPlay = useIsLoggedInToGame()
  const isAuthResolved = useSelector(getIsAuthResolved)

  useLayoutEffect(() => {
    loginParams.checkLoginID(
      id,
      protectedCallback,
      isAuthResolved && readyToCall,
      isUserReadyToPlay,
      localStorage,
      restoreDataCallback,
    )
  }, [id, readyToCall, isUserReadyToPlay, isAuthResolved, protectedCallback, localStorage, restoreDataCallback])

  return (...payload: T) => {
    if (isUserReadyToPlay) {
      protectedCallback(...payload)
      return
    }

    // set payload
    let returnPayload = null
    if (localStorage) {
      // set payload in the local storage
      localStorageSetItem(id, JSON.stringify(payload))
    } else {
      // set payload in the return url
      returnPayload = payload.length === 0 ? null : JSON.stringify(payload)
    }

    const returnUrl = getUriWithParam(baseReturnUrl, {
      login_component: id,
      login_payload: returnPayload,
    })

    dispatch(login(method, { returnUrl }))
  }
}

//
//
const useIsLoggedInToGame = () => {
  const isLoggedIn = useSelector(getIsLoggedIn)
  const isValidUser = useSelector(getHasUserNickname)
  const isAgreementsSigned = useSelector(getIsAgreementsSigned)
  const hasUserFullAddress = useSelector(getHasUserFullAddress)

  return Boolean(isLoggedIn && isValidUser && isAgreementsSigned && hasUserFullAddress)
}

//
//
const getCurrentLoginParams = () => {
  const url = new URL(window.location.href)
  const search = new URLSearchParams(url.search)
  let handlerID = search.get('login_component')
  const payloadParam = search.get('login_payload')
  let payload = JSON.parse(payloadParam || 'null')

  if (handlerID != null || payloadParam != null) {
    search.delete('login_component')
    search.delete('login_payload')

    url.search = String(search)
    window.history.replaceState(null, '', url.href)
  }

  const checkLoginID = <T extends unknown[]>(
    id: string,
    protectedCallback: (...payload: T) => void,
    readyToCall: boolean,
    isUserReadyToPlay: boolean,
    localStorage: boolean,
    restoreDataCallback: ((...payload: T) => void) | null,
  ) => {
    if (handlerID === id) {
      if (readyToCall) {
        // try to get payload from localstorage
        if (localStorage) {
          const localStoragePayload = localStorageGetParsedItem(id)
          payload = localStoragePayload ? localStoragePayload : payload
          localStorageRemoveItem(id)
        }
        const callbackArgs = payload ?? []
        handlerID = null
        payload = null
        if (isUserReadyToPlay) {
          if (callbackArgs.length) {
            restoreDataCallback && restoreDataCallback(...callbackArgs)
            protectedCallback(...callbackArgs)
          } else {
            const error = new Error(
              'useProtectedCallback did not call the provided callback because of missing callback arguments',
            )
            console.error(error)
          }
        } else {
          // A user has rejected login process and auth page redirected
          // to the returnUrl without actual login.
          // So do nothing.
        }
      }
    }
  }

  return {
    checkLoginID,
  }
}

const loginParams = getCurrentLoginParams()

//
//
const useUniqueID = (id: string) => {
  const ref = useRef()
  if (uniqueIDs.has(id) && uniqueIDs.get(id) !== ref) {
    throw new Error(`${id} is not unique`)
  }

  useLayoutEffect(() => {
    if (uniqueIDs.has(id)) {
      throw new Error(`${id} is not unique`)
    }
    uniqueIDs.set(id, ref)
    return () => {
      uniqueIDs.delete(id)
    }
  }, [id])
}

const uniqueIDs = new Map<string, MutableRefObject<undefined>>()

//
//
const getUriWithParam = (baseUrl: string, params: Record<string, string | undefined | null>): string => {
  const url = new URL(baseUrl)
  const urlParams = new URLSearchParams(url.search)
  for (const [key, value] of Object.entries(params)) {
    if (value != null) {
      urlParams.set(key, value)
    } else {
      urlParams.delete(key)
    }
  }
  url.search = urlParams.toString()
  return url.toString()
}
