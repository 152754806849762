import styled from 'styled-components/macro'

import { config } from 'src/config'
import { pixelify } from 'src/utils/style/pixelify'
import { useHeader } from 'src/containers/Header/useHeader'

import { Image } from 'src/components/Image'
import { UserInfoBlock } from './UserInfoBlock'

import { ReactComponent as UserIcon } from 'src/assets/images/user_icon.svg'
import blickChLogo from 'src/assets/images/logo/blick_logo.svg'

const LOGO_WIDTH_MOBILE = 110

export const Header = () => {
  const { onClickUserIcon, isLoggedIn, onClickUserInfo } = useHeader()

  return (
    <>
      <Wrapper>
        <InnerWrapper>
          <StyledLink href={config.externalLinks.blickUrl} target="_blank" aria-label={'Blick Logo'}>
            <Logo src={blickChLogo} />
          </StyledLink>
          <RightMenu>
            {isLoggedIn ? <UserInfoBlock onClick={onClickUserInfo} /> : <StyledUserIcon onClick={onClickUserIcon} />}
          </RightMenu>
        </InnerWrapper>
      </Wrapper>
    </>
  )
}

const StyledLink = styled.a`
  display: inline-block;
  height: 100%;
  ${({ theme }) => theme.media.isMobile} {
    padding-bottom: 3px;
  }
`

const Logo = styled(Image)`
  height: 100%;
`

const InnerWrapper = styled.div`
  width: 100%;
  max-width: ${({ theme }) => theme.measures.contentWidth};
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: auto;
  box-sizing: border-box;
  height: 48px;
  padding: 0 16px 0 8px;

  -ms-overflow-style: none;
  scrollbar-width: none;

  ${({ theme }) => theme.media.isDesktop} {
    height: 60px;
    padding: 0px;
  }
`

const Wrapper = styled.div`
  position: sticky;
  top: 0;
  z-index: ${({ theme }) => theme.zIndex.header};

  background-color: white;

  box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.17);
`

const RightMenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1 1 auto;
  height: 100%;

  svg {
    fill: currentColor;
  }
  ${({ theme }) => theme.media.isMobile} {
    max-width: calc(100% - ${pixelify(LOGO_WIDTH_MOBILE)});
    overflow: hidden;
  }
`

const StyledUserIcon = styled(UserIcon)`
  cursor: pointer;
  height: 23px;
  border-radius: 50%;
  color: #545454;
  ${({ theme }) => theme.media.isDesktop} {
  }
`
