import styled from 'styled-components/macro'

import { ReactComponent as FacebookIcon } from 'src/assets/images/facebook_icon.svg'
import { TCommonSocialMediaButtonProps } from './types'
import { objectToGetParams } from './utils'
import { shareButtonStyles } from './styles'

function facebookLink(url: string, title?: string) {
  return (
    'https://www.facebook.com/sharer/sharer.php' +
    objectToGetParams({
      u: url,
      quote: title,
    })
  )
}

export const FacebookShareButton = ({
  urlToShare,
  title,
  target = '_self',
  disabled,
  onClick,
  ...restProps
}: TCommonSocialMediaButtonProps) => {
  const url = urlToShare ? urlToShare : window.location.href
  const link = facebookLink(url, title)

  return (
    <StyledShareButton
      href={link}
      target={target}
      $disabled={disabled}
      onClick={() => onClick && onClick(link)}
      {...restProps}
    >
      <FacebookIcon />
    </StyledShareButton>
  )
}

const StyledShareButton = styled.a<{ $disabled?: boolean }>`
  ${shareButtonStyles}
  ${({ $disabled }) => {
    if ($disabled) {
      return `
        opacity:.5;
        pointer-events:none;
      `
    }
  }}
`
