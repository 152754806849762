import styled from 'styled-components/macro'

export const StarsWrapper = styled.div`
  display: inline-flex;
  max-width: 100%;
  margin: auto;
  flex-direction: column;
  width: 312px;
`

export const StarsRow = styled.div`
  display: inline-flex;
  justify-content: space-between;
`

export const StarsLeftText = styled.div`
  width: 50%;
  margin-top: 14px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  font-family: ${({ theme }) => theme.fonts.sourceSansPro};
  text-align: left;
  padding-left: 8px;

  ${({ theme }) => theme.media.isDesktop} {
    font-size: 16px;
    padding-left: 12px;
  }
`

export const StarsRightText = styled(StarsLeftText)`
  text-align: right;
  padding-left: 0px;
  padding-right: 8px;

  ${({ theme }) => theme.media.isDesktop} {
    padding-right: 12px;
  }
`
