import styled from 'styled-components/macro'

type Props = {
  $underlined?: boolean
}

export const ActionButton = styled.button<Props>`
  position: relative;
  padding: 2px;
  display: flex;
  align-items: center;
  padding: 0;
  color: ${({ theme }) => theme.colors.blick.grey100};
  background-color: transparent;
  border: none;
  cursor: pointer;
  transition: opacity 0.2s ease;
  font-family: ${({ theme }) => theme.fonts.sourceSansPro};
  font-size: 18px;
  line-height: 27px;
  font-weight: 400;

  &:active {
    opacity: 0.5;
  }
  * + * {
    margin-left: 8px;
  }
  > span {
    text-decoration: ${({ $underlined }) => ($underlined ? 'underline' : 'none')};
  }
`
