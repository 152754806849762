import { useSelector } from 'react-redux'
import { getUserHasMarketingOptin } from 'src/api/auth/selectors'
import { Spacer } from 'src/components/Spacer'
import styled from 'styled-components/macro'
import { ReactComponent as NotificationIcon } from 'src/assets/images/notification_icon.svg'
import { ExternalLinkButton } from 'src/components/links/ExternalLinkButton'
import { config } from 'src/config'

export const NotificationSubscription = () => {
  const isSubscribed = useSelector(getUserHasMarketingOptin)

  return (
    <Wrap>
      {isSubscribed ? (
        <NotificationContainer>
          <div>
            <BlackNotificationIcon />
          </div>
          <Spacer size={4} v />
          Du hast den Gewinnspiel-Newsletter abonniert. So verpasst du kein Gewinnspiel mehr.
        </NotificationContainer>
      ) : (
        <NotificationContainer>
          <div>Abonniere unseren Newsletter um kein Gewinnspiel mehr zu verpassen.</div>
          <Spacer size={16} v />
          <StyledExternalLinkButton height="40px" componentTheme="black" href={config.externalLinks.blickSignUpUrl}>
            <NotificationIcon />
            <Spacer size={9} v />
            Newsletter abonnieren
          </StyledExternalLinkButton>
        </NotificationContainer>
      )}
    </Wrap>
  )
}

const StyledExternalLinkButton = styled(ExternalLinkButton)`
  padding: 10px 24px;
`

export const Wrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

export const NotificationContainer = styled.div`
  background: ${({ theme }) => theme.colors.shades.white};
  border-radius: 8px;
  padding: 16px;
  text-align: center;
  width: 100%;
  max-width: 400px;
  margin-top: 24px;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  ${({ theme }) => theme.media.isMobile} {
    -webkit-transform: translateX(8px);
    -ms-transform: translateX(8px);
    transform: translateX(8px);
  }
`

export const BlackNotificationIcon = styled(NotificationIcon)`
  width: 24px;
  height: 24px;
  path {
    fill: ${({ theme }) => theme.colors.blick.grey100};
  }
`
