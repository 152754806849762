import styled from 'styled-components/macro'

import { BaseElement } from 'src/components/skeleton/elements/BaseElement'
import { Blend } from 'src/components/skeleton/elements/Blend'
import { Skeleton } from 'src/components/skeleton//Skeleton'
import { useMedia } from 'src/utils/media/useMedia'

export const ProductSkeleton = () => {
  const { isDesktop } = useMedia()

  return (
    <SkeletonWrap>
      <BaseElement width={'100%'} height={isDesktop ? '680px' : '500px'} />
      <Blend width={'100%'} height={'250px'} />
      <BaseElement width={'100%'} height={'300px'} />
    </SkeletonWrap>
  )
}

const SkeletonWrap = styled(Skeleton)`
  padding: 0;
  min-height: 100vh;
`
