import styled from 'styled-components/macro'

import { pixelify } from 'src/utils/style/pixelify'

type Props = {
  width?: string | number
  height?: string | number
  grow?: boolean
  shrink?: boolean
}

export const Blend = styled.div<Props>`
  min-height: ${({ height }) => (height ? pixelify(height) : '6px')};
  min-width: ${({ width }) => (width ? pixelify(width) : '100%')};
  background-color: ${({ theme }) => theme.colors.blick.white};
  ${({ grow }) => grow && 'flex-grow: 1;'}
  ${({ shrink }) => shrink && 'flex-shrink: 1;'}
`
