import { ComponentProps } from 'react'
import ReactMarkdown from 'react-markdown'
import styled from 'styled-components/macro'
import { ExternalLink } from 'src/components/links/ExternalLink'

type Props = ComponentProps<typeof ReactMarkdown>

export const Markdown = (props: Props) => {
  return (
    <StyledMarkdown
      components={{
        // Use `ExternalLink instead of anchor element `a`
        a: ({ ...props }) => {
          return <ExternalLink {...props} />
        },
      }}
      {...props}
    />
  )
}

const StyledMarkdown = styled(ReactMarkdown)`
  p {
    margin: 0;
    &:not(:last-child):not(:only-child) {
      margin-bottom: 24px;
    }
  }
`
