import styled from 'styled-components/macro'

import { toasty } from 'src/containers/toast/toasty'

import { FacebookShareButton } from 'src/components/buttons/share/FacebookShareButton'
import { TwitterShareButton } from 'src/components/buttons/share/TwitterShareButton'
import { WhatsappShareButton } from 'src/components/buttons/share/WhatsappShareButton'
import { EmailShareButton } from 'src/components/buttons/share/EmailShareButton'
import { CopyToClipboardShareButton } from 'src/components/buttons/share/CopyToClipboardShareButton'
import { ShareType } from 'src/components/buttons/share/enums'
import { colors } from 'src/theme/colors'
import { useEffect, useRef, useState } from 'react'
import { trackSocialShareClick, trackSocialShareImpression } from 'src/tracking/gtm/actions'
import { useElementOnScreen } from 'src/utils/dom/useElementOnScreen'
import { Spacer } from './Spacer'
import { Headline } from './typography/Headline'
import { HorisontalRule } from './HorisontalRule'

type Props = {
  urlToShare: string
  onShareButtonClick?: (shareType: ShareType) => void
  color?: string
}

export const Social = ({ urlToShare, onShareButtonClick, color = colors.shades.white }: Props) => {
  const title = `Hallo! Schau dir an, was du beim Blick Jackpot gewinnen kannst!`
  const subject = 'Mach mit beim Blick Jackpot'
  const [isImpressionSend, setIsImpressionSend] = useState(false)
  const containerRef = useRef<HTMLDivElement>(null)

  const [isVisible] = useElementOnScreen(
    {
      threshold: 1,
    },
    containerRef,
  )

  const handleShareButttonClick = (shareType: ShareType, sharedLink: string) => {
    trackSocialShareClick(shareType, sharedLink)

    if (!onShareButtonClick) {
      return
    }

    onShareButtonClick(shareType)
  }

  const onCopy = () => {
    toasty('Zu teilende URL wurde kopiert!', { type: 'info' })
    handleShareButttonClick(ShareType.Copy, urlToShare)
  }

  useEffect(() => {
    if (!isImpressionSend && isVisible) {
      trackSocialShareImpression()
      setIsImpressionSend(true)
    }
  }, [isVisible, isImpressionSend, setIsImpressionSend])

  return (
    <>
      <Headline>Spiel mit Freunden teilen</Headline>
      <Spacer v size={8} />
      <SocialWrap color={color} ref={containerRef}>
        <FacebookShareButton
          urlToShare={urlToShare}
          title={title}
          target="_blank"
          onClick={(sharedLink) => handleShareButttonClick(ShareType.Facebook, sharedLink)}
        />
        <TwitterShareButton
          urlToShare={urlToShare}
          title={title}
          target="_blank"
          onClick={(sharedLink) => handleShareButttonClick(ShareType.Twitter, sharedLink)}
        />
        <WhatsappShareButton
          urlToShare={urlToShare}
          title={title}
          target="_blank"
          onClick={(sharedLink) => handleShareButttonClick(ShareType.Whatsapp, sharedLink)}
        />
        <EmailShareButton
          urlToShare={urlToShare}
          subject={subject}
          body={title}
          onClick={() => handleShareButttonClick(ShareType.Email, urlToShare)}
        />
        <CopyToClipboardShareButton urlToShare={urlToShare} onCopy={onCopy} />
      </SocialWrap>
      <Spacer size={24} v />
      <HorisontalRule noMargin />
      <Spacer size={24} v />
    </>
  )
}

const SocialWrap = styled.div<{ color: string }>`
  display: flex;
  * + * {
    margin-left: 16px;
  }

  justify-content: center;

  svg {
    path {
      fill: ${({ color }) => color};
    }
    circle {
      stroke: ${({ color }) => color};
    }
  }
`
