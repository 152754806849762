import { TCampaignSymbol } from 'src/api/product/types'
import { Image } from 'src/components/Image'
import styled from 'styled-components/macro'
import { ItemSection } from './ItemSection'

type Props = {
  icon?: TCampaignSymbol | undefined
  hasImageUrl?: boolean
}

export const PrizeIcons = ({ icon, hasImageUrl }: Props) => {
  return (
    <IconsBlock $hasImageUrl={hasImageUrl}>
      <IconWrapper>
        <Icon src={icon?.imageUrl} />
      </IconWrapper>
      <IconWrapper>
        <Icon src={icon?.imageUrl} />
      </IconWrapper>
      <IconWrapper>
        <Icon src={icon?.imageUrl} />
      </IconWrapper>
    </IconsBlock>
  )
}

const IconsBlock = styled(ItemSection)<{ $hasImageUrl?: boolean }>`
  height: fit-content;
  display: flex;
  justify-content: start;
  ${({ $hasImageUrl }) => $hasImageUrl && 'margin-bottom: 16px;'}
  ${({ theme }) => theme.media.isMobile} {
    justify-content: center;
    margin-bottom: 16px;
  }
`

const IconWrapper = styled.div`
  height: 90px;
  width: 90px;
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-right: 22px;
  }
  ${({ theme }) => theme.media.isMobile} {
    height: 56px;
    width: 56px;
    &:not(:last-child) {
      margin-right: 24px;
    }
  }
`

const Icon = styled(Image)`
  max-width: 90px;
  max-height: 90px;

  ${({ theme }) => theme.media.isMobile} {
    max-width: 56px;
    max-height: 56px;
  }
`
