import styled from 'styled-components/macro'

import { SymbolWrap } from './CampaignSymbol'
import { TTimerSymbol } from './types'

import timerSymbolBg from 'src/assets/images/timer_symbol.svg'

type Props = {
  symbol: TTimerSymbol
}

export const TimerSymbol = ({ symbol }: Props) => {
  return (
    <SymbolWrap>
      <TimerSymbolWrap>
        <TimerValue>{symbol.value}</TimerValue>
        <TimerUnit>{symbol.unit}</TimerUnit>
      </TimerSymbolWrap>
    </SymbolWrap>
  )
}

const TimerSymbolWrap = styled.div`
  padding-top: 11px;
  width: 100%;
  height: 100%;
  background-image: url(${timerSymbolBg});
  background-size: cover;
  color: ${({ theme }) => theme.colors.blick.white};
`

const TimerValue = styled.span`
  display: block;
  font-family: ${({ theme }) => theme.fonts.goodOffcPro};
  font-size: 36px;
  line-height: 43px;
  font-weight: 900;
  letter-spacing: 0.5px;
  text-align: center;
  ${({ theme }) => theme.media.isMobile} {
    font-size: 24px;
    line-height: 24px;
  }
`
const TimerUnit = styled.span`
  display: block;
  font-family: ${({ theme }) => theme.fonts.goodOffcPro};
  font-weight: 400;
  font-size: 22px;
  line-height: 26px;
  letter-spacing: 0.5px;
  text-align: center;
  text-transform: uppercase;
  ${({ theme }) => theme.media.isMobile} {
    font-size: 14px;
    line-height: 18px;
  }
`
