import styled from 'styled-components/macro'
import { useSelector } from 'react-redux'

import { getUserNickName } from 'src/api/auth/selectors'

import { ReactComponent as ArrowIcon } from 'src/assets/images/chevronDown_icon.svg'

type Props = {
  onClick?: () => void
}

export const UserInfoBlock = ({ onClick }: Props) => {
  const userNickName = useSelector(getUserNickName)

  return (
    <InfoBlock onClick={onClick}>
      <UserName>{userNickName}</UserName>
      <StyledArrowIcon />
    </InfoBlock>
  )
}

const InfoBlock = styled.button`
  display: flex;
  align-items: center;
  padding: 6px 12px;
  font-family: inherit;
  background-color: #fff;
  border: 1px solid ${({ theme }) => theme.colors.blick.grey80};
  color: ${({ theme }) => theme.colors.blick.grey90};
  border-radius: 20px;
  cursor: pointer;
  ${({ theme }) => theme.media.isMobile} {
    max-width: calc(100% - 16px);
    padding: 2px 12px;
  }
`

const UserName = styled.div`
  margin-right: 16px;
  position: relative;
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  letter-spacing: 0.01em;
  width: calc(100%);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const StyledArrowIcon = styled(ArrowIcon)`
  width: 12px;
  min-width: 12px;
`
