import styled from 'styled-components/macro'

export const UserIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  font-size: 11px;
  line-height: 17px;
  border: 1px solid ${({ theme }) => theme.colors.blick.brand100};
  border-radius: 50%;
  text-transform: uppercase;
`
