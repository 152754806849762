import styled from 'styled-components/macro'
import { useState } from 'react'

import { FOOTER_MAX_WIDTH } from './constants'

import { ReactComponent as ChevronDownIcon } from 'src/assets/images/chevronDown_icon.svg'

type Props = {
  children: React.ReactNode
  title: string
  closedByDefault?: boolean
}

export const Collapsible = ({ children, title, closedByDefault = false }: Props) => {
  const [isCollapsed, setIsCollapsed] = useState(closedByDefault)

  const onHeaderClick = () => {
    return setIsCollapsed(!isCollapsed)
  }

  return (
    <>
      <Header onClick={onHeaderClick} $isCollapsed={isCollapsed}>
        <HeaderWrap>
          <Title>{title}</Title>
          <IconWrapper>{isCollapsed ? <ChevronDownIcon /> : <ChevronUpIcon />}</IconWrapper>
        </HeaderWrap>
      </Header>

      {!isCollapsed && <Content>{children}</Content>}
    </>
  )
}

const Header = styled.div<{ $isCollapsed: boolean }>`
  margin: 0 auto;
  max-width: ${({ theme }) => theme.measures.contentWidth};
  padding: 16px;
  background-color: ${({ theme }) => theme.colors.blick.grey10};

  cursor: pointer;

  svg {
    width: 12px;
  }
`

const HeaderWrap = styled.div`
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  max-width: ${FOOTER_MAX_WIDTH}px;
`

const Content = styled.span`
  display: block;
  padding: 0 24px 24px;
  text-align: left;
  ${({ theme }) => theme.media.isMobile} {
    padding: 0 16px 24px;
  }
`

const Title = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
`

const IconWrapper = styled.div`
  margin-right: 16px;
`

const ChevronUpIcon = styled(ChevronDownIcon)`
  transform: Rotate(180deg);
`
