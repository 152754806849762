import { config } from 'src/config'

export const getAuthLoginCase = (): string => {
  return config.api.onelog.loginCase
}

export const getSelfUrl = (): string => {
  const configSelfUrl = config.selfUrl || window.location.origin

  return configSelfUrl
}

export const getProductFromPath = () => {
  return window.location.pathname.split('/')[1]
}
