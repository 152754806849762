import { TCampaignSymbol, TSlotMachineConfig } from 'src/api/product/types'
import { SlotMachine } from 'src/containers/SlotMachine/SlotMachine'
import symbol1 from 'src/assets/images/mock/symbol-1.svg'
import symbol2 from 'src/assets/images/mock/symbol-2.svg'
import symbol3 from 'src/assets/images/mock/symbol-3.svg'
import styled from 'styled-components/macro'

import { ReactComponent as GiftboxIcon } from 'src/assets/images/giftbox_icon.svg'
import { ReactComponent as PeopleIcon } from 'src/assets/images/people_icon.svg'
import { ReactComponent as MedalIcon } from 'src/assets/images/medal_icon_alt.svg'
import { Spacer } from 'src/components/Spacer'
import { Social } from 'src/components/Social'
import { Path } from 'src/router/enums'
import { PlayState } from 'src/enums/slotMachine'
import { colors } from 'src/theme/colors'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getProductStats, getIsProductResolved } from 'src/api/product/selectors'
import { fetchProductStats } from 'src/api/product/actions'
import { ProductSkeleton } from 'src/components/skeleton/presets/Product'
import { SubscriptionSection } from 'src/containers/SubscriptionSection'
import { Container } from 'src/components/Container'
import { useMedia } from 'src/utils/media/useMedia'
import { HorisontalRule } from 'src/components/HorisontalRule'
import { StyledSectionTitle, Text } from 'src/containers/Community/Community'
import { ExternalLink } from 'src/components/links/ExternalLink'
import { config as appConfig } from 'src/config'

type Props = {
  productId?: string
}

const config: TSlotMachineConfig = {
  numberOfSpinners: 3,
}

export const symbols: TCampaignSymbol[] = [
  {
    id: 1,
    imageUrl: symbol1,
  },
  {
    id: 2,
    imageUrl: symbol2,
  },
  {
    id: 3,
    imageUrl: symbol3,
  },
]

export const NoGameProductContainer = ({ productId = '' }: Props) => {
  const dispatch = useDispatch()
  const isProductResolved = useSelector(getIsProductResolved)
  const urlToShare = new URL(`${Path.Product}`.replace(':productId', productId), window.location.href).href
  const productStats = useSelector(getProductStats)
  const { isDesktop } = useMedia()

  useEffect(() => {
    dispatch(fetchProductStats({ productId: productId }))
  }, [dispatch, productId])

  return (
    <>
      {isProductResolved ? (
        <>
          <SlotMachine
            symbols={symbols}
            config={config}
            productId={productId}
            reelsText="Mitspielen und gewinnen!"
            id="no_product_slot_machine"
            playStateOverride={PlayState.NoGame}
          />
        </>
      ) : (
        <>
          <ProductSkeleton />
        </>
      )}
      <StyledContainer>
        <SubscriptionSection />
        <Social urlToShare={urlToShare} color={colors.blick.grey100} />
        <StatisticsWrapper>
          {!!productStats?.statistics.winners.length && (
            <>
              <MedalIcon />
              <Headline>Gratulation an die letzten Gewinner</Headline>
              <Spacer v size={10} />
              <Winners>
                {productStats?.statistics.winners.map((winner, index) => (
                  <span key={`winner-${index}`}>
                    {`${winner.nickname}, ${winner.city}`}
                    <br />
                  </span>
                ))}
              </Winners>
              <Spacer v size={isDesktop ? 64 : 40} />
            </>
          )}
          <GiftboxIcon />
          <Headline>
            Über {productStats?.statistics.totalPrizesWon} Preise wurden bisher
            <br /> gewonnen
          </Headline>
          <Spacer v size={isDesktop ? 64 : 40} />
          <StyledPeopleIcon />
          <Headline>
            {productStats?.statistics.totalNumberOfPlayers} Spieler haben ihr Glück
            <br /> versucht
          </Headline>
          <Spacer v size={isDesktop ? 64 : 40} />
          <HorisontalRule noMargin />
          <Spacer v size={isDesktop ? 64 : 40} />
          <GiftboxIcon />
          <Spacer v size={8} />
          <StyledSectionTitle>Du magst Spiele und coole Preise?</StyledSectionTitle>
          <Spacer v size={8} />
          <Text>
            <ExternalLink href={appConfig.externalLinks.blickSkiTrophyUrl}>
              Mach mit bei der Blick Ski Trophy
            </ExternalLink>
            ! Beantworte das tägliche Quiz und tippe auf alle Ski Rennen. <br />
            <br /> Jeden Monat gibt’s tolle Preise zu gewinnen.{' '}
            <ExternalLink href={appConfig.externalLinks.blickSkiTrophyUrl}>Los geht’s</ExternalLink>!
          </Text>
          <Spacer v size={144} />
        </StatisticsWrapper>
      </StyledContainer>
    </>
  )
}

const StyledContainer = styled(Container)`
  max-width: 654px;
`

const StatisticsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Headline = styled.div`
  font-family: ${({ theme }) => theme.fonts.goodOffcPro};
  font-style: normal;
  font-weight: 950;
  font-size: 36px;
  line-height: 44px;
  text-align: center;
  ${({ theme }) => theme.media.isMobile} {
    font-size: 28px;
    line-height: 36px;
  }
`

const Winners = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 34px;
  text-align: center;
`

const StyledPeopleIcon = styled(PeopleIcon)`
  path {
    fill: ${({ theme }) => theme.colors.blick.brand};
  }
`
