import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { getIsAudioEnabled } from 'src/state/app/selectors'

export const useAudio = (audioSrc: string) => {
  const isAudioEnabled = useSelector(getIsAudioEnabled)

  const audio = useMemo(() => new Audio(audioSrc), [audioSrc])

  useEffect(() => {
    audio.muted = !isAudioEnabled
  }, [isAudioEnabled, audio])

  return { audio }
}
