import { Reducer } from 'redux'

import { TActions, TProductState } from './types'
import { ActionTypes } from './enums'

import { getReduxFailureStates, getReduxFetchStates, getReduxSuccessStates } from 'src/utils/redux'

export const initialState: TProductState = {
  data: null,
  stats: null,
  gameResult: null,
  loading: {
    fetchProduct: false,
    fetchStats: false,
    playGame: false,
  },
  error: {
    fetchProduct: null,
    fetchStats: null,
    playGame: null,
  },
  resolved: {
    fetchProduct: false,
    fetchStats: false,
    playGame: false,
  },
}

export const reducer: Reducer<TProductState, TActions> = (state = initialState, action): TProductState => {
  switch (action.type) {
    case ActionTypes.FETCH_PRODUCT: {
      return {
        ...state,
        ...getReduxFetchStates(state, 'fetchProduct'),
      }
    }
    case ActionTypes.FETCH_PRODUCT_SUCCESS: {
      return {
        ...state,
        data: action.payload,
        ...getReduxSuccessStates(state, 'fetchProduct'),
      }
    }
    case ActionTypes.FETCH_PRODUCT_FAILURE: {
      return {
        ...state,
        ...getReduxFailureStates(state, 'fetchProduct', action.payload),
      }
    }

    case ActionTypes.FETCH_PRODUCT_STATS: {
      return {
        ...state,
        ...getReduxFetchStates(state, 'fetchStats'),
      }
    }
    case ActionTypes.FETCH_PRODUCT_STATS_SUCCESS: {
      return {
        ...state,
        stats: action.payload,
        ...getReduxSuccessStates(state, 'fetchStats'),
      }
    }
    case ActionTypes.FETCH_PRODUCT_STATS_FAILURE: {
      return {
        ...state,
        ...getReduxFailureStates(state, 'fetchStats', action.payload),
      }
    }

    case ActionTypes.PLAY_GAME: {
      return {
        ...state,
        ...getReduxFetchStates(state, 'playGame'),
      }
    }
    case ActionTypes.PLAY_GAME_SUCCESS: {
      const currentUserPlaysLeft = state.data?.currentUser?.playsLeft
      return {
        ...state,
        ...(!!currentUserPlaysLeft &&
          state.data && {
            data: {
              ...state.data,
              currentUser: {
                ...state.data?.currentUser,
                playsLeft: currentUserPlaysLeft - 1,
              },
            },
          }),
        gameResult: action.payload,
        ...getReduxSuccessStates(state, 'playGame'),
      }
    }
    case ActionTypes.PLAY_GAME_FAILURE: {
      return {
        ...state,
        ...getReduxFailureStates(state, 'playGame', action.payload),
      }
    }
    case ActionTypes.PLAY_GAME_RESET: {
      return {
        ...state,
        gameResult: null,
        loading: {
          ...state.loading,
          playGame: false,
        },
        resolved: {
          ...state.resolved,
          playGame: false,
        },
      }
    }
    default: {
      return state
    }
  }
}
