import { useSelector } from 'react-redux'
import { getIsProductResolved } from 'src/api/product/selectors'
import { TProduct } from 'src/api/product/types'
import { ProductSkeleton } from 'src/components/skeleton/presets/Product'
import { Community } from 'src/containers/Community/Community'
import { Prizes } from 'src/containers/Prizes/Prizes'
import { Rules } from 'src/containers/Rules/Rules'
import { SlotMachine } from 'src/containers/SlotMachine/SlotMachine'
import { PlayState } from 'src/enums/slotMachine'
import { FeedbackModal } from 'src/containers/Feedback/FeedbackModal'
import { useState } from 'react'
import { getFeedback } from 'src/api/feedback/selectors'
import { useMedia } from 'src/utils/media/useMedia'
import { trackFeedbackImpression } from 'src/tracking/gtm/actions'

type Props = {
  productId: string
  productData: TProduct | null
}

export const ProductContainer = ({ productId, productData }: Props) => {
  const isProductResolved = useSelector(getIsProductResolved)
  const feedback = useSelector(getFeedback)
  const { isMobile } = useMedia()
  const [isFeedbackModalShown, setIsFeedbackModalShown] = useState(false)

  const onShowFeedbackModal = () => {
    setIsFeedbackModalShown(true)
    trackFeedbackImpression()
  }

  return (
    <>
      {isProductResolved && productData && productData.currentCampaign ? (
        <>
          <SlotMachine
            symbols={productData.currentCampaign.symbols}
            config={productData.currentCampaign.slotmachineConfig}
            productId={productId}
            playStateOverride={productData.currentUser?.didAlreadyWin ? PlayState.АlreadyWonOnce : undefined}
            onShowFeedbackModal={onShowFeedbackModal}
            feedback={feedback}
          />
          <Prizes
            productId={productId}
            campaignId={productData.currentCampaign.id}
            sponsoring={productData.sponsoring}
            infoText={productData.currentCampaign.infoText}
          />
          {productId && <Community productId={productId} />}
          <Rules />

          {feedback && (
            <FeedbackModal
              productId={productId}
              campaignId={productData.currentCampaign.id}
              style={{
                content: {
                  background: '#FFFFFF',
                  maxWidth: '560px',
                  padding: isMobile ? '16px' : '32px',
                },
              }}
              isOpen={isFeedbackModalShown}
              onRequestClose={() => setIsFeedbackModalShown(false)}
              onClose={() => setIsFeedbackModalShown(false)}
              feedback={feedback}
            />
          )}
        </>
      ) : (
        <ProductSkeleton />
      )}
    </>
  )
}
