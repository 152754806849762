import styled from 'styled-components/macro'
import { useDispatch, useSelector } from 'react-redux'

import { useEffect } from 'react'
import { fetchPrizes } from 'src/api/prizes/actions'
import { getIsPrizesLoading, getPrizes } from 'src/api/prizes/selectors'
import { Prize } from './Prize/Prize'
import { PrizesSkeleton } from 'src/components/skeleton/presets/Prizes'
import { SponsoringHeader } from './Sponsoring/SponsoringHeader'
import { SponsoringFooter } from './Sponsoring/SponsoringFooter'
import { TSponsoring } from 'src/api/product/types'
import { colors } from 'src/theme/colors'
import { Path } from 'src/router/enums'
import { Social } from 'src/components/Social'
import { Spacer } from 'src/components/Spacer'
import { useMedia } from 'src/utils/media/useMedia'
import { getPlayState } from 'src/containers/SlotMachine/utils/utils'
import { getGameResult, getProductCurrentCampaign, getProductUserConfig } from 'src/api/product/selectors'
import { PlayState } from 'src/enums/slotMachine'
import { SubscriptionSection } from 'src/containers/SubscriptionSection'

type Props = {
  campaignId: number
  sponsoring: null | TSponsoring
  infoText: string
  productId: string
}

export const Prizes = ({ campaignId, sponsoring, infoText, productId }: Props) => {
  const dispatch = useDispatch()
  const prizes = useSelector(getPrizes)
  const currentUser = useSelector(getProductUserConfig)
  const currentCampaign = useSelector(getProductCurrentCampaign)
  const gameResult = useSelector(getGameResult)
  const isPrisezLoading = useSelector(getIsPrizesLoading)
  const urlToShare = new URL(`${Path.Product}`.replace(':productId', productId), window.location.href).href
  const { isDesktop } = useMedia()
  const playState = currentCampaign && getPlayState(gameResult, currentUser, currentCampaign)
  const noPlaysLeft = playState === PlayState.NoPlaysLeft

  useEffect(() => {
    if (campaignId) {
      dispatch(fetchPrizes({ campaignId: campaignId }))
    }
  }, [campaignId, dispatch])

  return (
    <PrizesContainer $isLoading={isPrisezLoading}>
      <PrizesWrapper $isLoading={isPrisezLoading}>
        <Social urlToShare={urlToShare} color={colors.blick.grey100} />
        {noPlaysLeft && <SubscriptionSection />}
        {(isDesktop || noPlaysLeft) && <Spacer v size={16} />}
        {sponsoring && (
          <SponsoringHeader
            logoUrl={sponsoring.logoImageUrl}
            imageUrls={sponsoring.imageUrls}
            logoWidth={sponsoring.logoWidth || 100}
          ></SponsoringHeader>
        )}
        {isPrisezLoading ? (
          <PrizesSkeleton />
        ) : (
          prizes?.map((prize, index) => <Prize key={index + prize.id * 2} prizeData={prize} />)
        )}
        {(sponsoring || infoText) && (
          <SponsoringFooter description={sponsoring?.text} infoText={infoText}></SponsoringFooter>
        )}
      </PrizesWrapper>
    </PrizesContainer>
  )
}

const PrizesContainer = styled.div<{ $isLoading: boolean }>`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: ${({ $isLoading }) => ($isLoading ? '0px' : '24px 0 64px')};
  ${({ theme }) => theme.media.isMobile} {
    padding: ${({ $isLoading }) => ($isLoading ? '0px' : '24px 16px 44px')};
  }
`
const PrizesWrapper = styled.div<{ $isLoading: boolean }>`
  width: ${({ theme, $isLoading }) => ($isLoading ? '100%' : theme.measures.contentWidthNarrow)};
  ${({ theme }) => theme.media.isMobile} {
    width: inherit;
    max-width: ${({ theme }) => theme.measures.contentWidthNarrow};
  }
`
