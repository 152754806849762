import { useSelector } from 'react-redux'
import { TPrize } from 'src/api/prizes/types'
import { getSymbol } from 'src/api/product/selectors'
import { Image } from 'src/components/Image'
import styled from 'styled-components/macro'
import { ItemSection } from './ItemSection'
import { PrizeIcons } from './PrizeIcons'
import { PrizeInfo } from './PrizeInfo'

type Props = {
  prizeData: TPrize
}

export const Prize = ({ prizeData }: Props) => {
  const { symbolId, name, description, imageUrl } = prizeData
  const icon = useSelector(getSymbol(symbolId))
  return (
    <>
      {!imageUrl ? (
        <PrizeWrapper>
          <PrizeIcons icon={icon} />
          <PrizeInfo name={name} description={description} />
        </PrizeWrapper>
      ) : (
        <PrizeWrapper>
          <ItemSection>
            <PrizeIcons icon={icon} hasImageUrl />

            <PrizeInfo name={name} description={description} />
          </ItemSection>
          <ImageWrapper>
            <Image src={imageUrl} />
          </ImageWrapper>
        </PrizeWrapper>
      )}
    </>
  )
}

const PrizeWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  &:not(:last-child) {
    margin-bottom: 40px;
  }
  ${({ theme }) => theme.media.isMobile} {
    justify-content: center;
  }
`

export const ImageWrapper = styled(ItemSection)`
  height: fit-content;
  aspect-ratio: 16 / 9;
  height: 176px;
  width: 313px;
  display: flex;
  justify-content: center;
  background-color: #121931;

  img {
    width: 313px;
  }
  ${({ theme }) => theme.media.isMobile} {
    width: 343px;
    height: 192px;
    text-align: center;
    img {
      width: 343px;
    }
  }
`
