import { useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { DrawerContext, DRAWER_INDEX } from 'src/drawer'
import { login } from 'src/api/auth/actions'
import { getIsLoggedIn, getUserNickName } from 'src/api/auth/selectors'
import { LoginMethods } from 'src/enums/auth'

import { UserDashBoardHeader } from 'src/containers/UserDashboard/UserDashboardHeader'
import { UserDashBoardContent } from 'src/containers/UserDashboard/UserDashboardContent'
import { UserDashBoardFooter } from 'src/containers/UserDashboard/UserDashboardFooter'
import { trackUserDashboardOpen } from 'src/tracking/gtm/actions'

export const useHeader = () => {
  const dispatch = useDispatch()

  const { openDrawer } = useContext(DrawerContext)

  const userNickName = useSelector(getUserNickName)
  const isLoggedIn = useSelector(getIsLoggedIn)

  const onLoginClick = () => dispatch(login(LoginMethods.HEADER, { returnUrl: window.location.href }))

  const onClickUserIcon = () => {
    onLoginClick()
  }

  const onClickUserInfo = () => {
    trackUserDashboardOpen()
    openDrawer({
      id: DRAWER_INDEX.USER_DASHBOARD,
      content: <UserDashBoardContent />,
      header: <UserDashBoardHeader />,
      footer: <UserDashBoardFooter />,
    })
  }

  return {
    userNickName,
    isLoggedIn,
    onClickUserIcon,
    onClickUserInfo,
  }
}
