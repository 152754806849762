import styled from 'styled-components/macro'

export const ModalButtonsWrapper = styled.div<{ align?: 'flex-start' | 'center' | 'flex-end' }>`
  display: flex;
  flex-wrap: wrap;
  align-items: end;
  justify-content: ${({ align }) => align || 'flex-end'};
  button + button {
    margin-left: 16px;
  }
`
