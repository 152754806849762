import { css } from 'styled-components/macro'

export const shareButtonStyles = css`
  display: inline-block;
  transition: opacity 0.3s ease;
  cursor: pointer;
  &:active {
    opacity: 0.7;
  }
  svg {
    vertical-align: bottom;
  }
`
