import styled from 'styled-components/macro'
import { useContext } from 'react'
import { useSelector } from 'react-redux'

import { getUserNickName } from 'src/api/auth/selectors'

import { DRAWER_INDEX } from 'src/drawer/constants'
import { DrawerContext } from 'src/drawer'
import { DrawerTitle, DrawerCloseButton } from 'src/drawer/components'

export const UserDashBoardHeader = () => {
  const { closeDrawer } = useContext(DrawerContext)

  const userNickName = useSelector(getUserNickName)

  const onCloseDashBoard = () => {
    closeDrawer(DRAWER_INDEX.USER_DASHBOARD)
  }

  return (
    <>
      <HeaderBox>
        <DrawerTitle $centered>{userNickName}</DrawerTitle>
        <DrawerCloseButton onClick={onCloseDashBoard} />
      </HeaderBox>
    </>
  )
}

const HeaderBox = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 32px 32px 32px;
  &:after {
    content: '';
    position: absolute;
    width: calc(100% - 64px);
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    height: 1px;
    background-color: #e4e4e4;
  }
  ${({ theme }) => theme.media.isMobile} {
    padding: 16px 16px 8px;
    &:after {
      width: calc(100% - 32px);
    }
  }
`
