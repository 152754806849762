import styled from 'styled-components/macro'

type Props = {
  $noBorder?: boolean
  $wide?: boolean
}

export const Section = styled.div<Props>`
  position: relative;
  padding: 24px 32px;
  &:empty {
    padding: 0 32px 24px;
  }
  &:not(:last-child) {
    &:after {
      display: ${({ $noBorder }) => ($noBorder ? 'none' : 'block')};
      content: '';
      position: absolute;
      width: calc(100% - 64px);
      height: 1px;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      background-color: ${({ theme }) => theme.colors.blick.grey10};
    }
  }
  ${({ theme }) => theme.media.isMobile} {
    padding: 24px 16px;
    &:not(:last-child) {
      &:after {
        width: calc(100% - 32px);
      }
    }
  }
`
