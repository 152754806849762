import styled from 'styled-components/macro'

import { ReactComponent as CopyToClipboardIcon } from 'src/assets/images/link_copy_icon.svg'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { shareButtonStyles } from './styles'

type Props = {
  urlToShare?: string
  onCopy?: (text: string, result: boolean) => void
  disabled?: boolean
}

export const CopyToClipboardShareButton = ({ urlToShare, onCopy, disabled }: Props) => {
  const url = urlToShare ? urlToShare : window.location.href

  return (
    <CopyToClipboard text={url} onCopy={onCopy}>
      <StyledShareButton $disabled={disabled}>
        <CopyToClipboardIcon />
      </StyledShareButton>
    </CopyToClipboard>
  )
}

const StyledShareButton = styled.a<{ $disabled?: boolean }>`
  ${shareButtonStyles}
  ${({ $disabled }) => {
    if ($disabled) {
      return `
        opacity:.5;
        pointer-events:none;
      `
    }
  }}
`
