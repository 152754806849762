import { ToastOptions } from 'react-toastify'
import { AxiosError, AxiosResponse } from 'axios'
import { HttpErrorMessage } from 'src/enums/error'
import { toasty } from 'src/containers/toast/toasty'

export type THttpError = {
  status: number | undefined
  message: HttpErrorMessage
  xTraceId?: string
}

const HttpErrorMessageTranslation = {
  [HttpErrorMessage.MaximumPlaysReached]: 'Maximum plays in a day already reached',
}

export const DEFAULT_ERRORS = {
  TRY_LATER: 'Unfortunately, something went wrong. Please try again later.',
  TRY_REFRESH: 'Unfortunately, something went wrong. Try to refresh the page.',
}

export const isBackendError = <T>(response: T | AxiosError): response is AxiosError => {
  if ((response as AxiosError).isAxiosError) {
    return true
  } else return false
}

export const isHttpError = <T>(error: T | THttpError): error is THttpError => {
  if ((error as THttpError).message && (error as THttpError).status) {
    return true
  } else return false
}

const extractBackendErrorMessage = (response: AxiosError): string | undefined => {
  if (typeof response.response?.data === 'string') {
    return response.response?.data
  }
  if ((response.response?.data as Error)?.message) {
    return (response.response?.data as Error)?.message
  }
}

export const getReduxErrorObject = (error: unknown): AnyObject => {
  if (error instanceof Error) {
    return {
      error: error.message,
    }
  }
  return { error }
}

export const getBackendError = (res: AxiosResponse): AxiosError | null => {
  if (isBackendError(res)) {
    return res as AxiosError
  }
  return null
}

export const HttpError = (error: AxiosError, defaultError: string) => {
  const errorMessage = extractBackendErrorMessage(error) ? extractBackendErrorMessage(error) : defaultError
  return {
    status: error.response?.status,
    message: errorMessage,
    xTraceId: error.response?.headers['x-trace-id'],
  }
}

const translateHttpError = (message: HttpErrorMessage) => {
  return HttpErrorMessageTranslation[message] || DEFAULT_ERRORS.TRY_REFRESH
}

export const showToastError = <T>(error: T, options?: ToastOptions) => {
  if (isBackendError(error)) {
    const httpError = HttpError(error as AxiosError, 'Unknown error')
    const message = translateHttpError(httpError.message as HttpErrorMessage)

    toasty(message, options)
  } else {
    toasty(DEFAULT_ERRORS.TRY_LATER, options)
  }
}
