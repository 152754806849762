import styled, { css } from 'styled-components/macro'

import { buttonTheme, buttonStyles, TButtonTheme } from 'src/components/buttons/Button'
import { SyntheticEvent, memo } from 'react'
import { getChildrenText } from 'src/utils/dom/getChildrenText'
import { trackExternalLinkClick, trackNewsNotifyLinkClick } from 'src/tracking/gtm/actions'
import { config } from 'src/config'

type Props = {
  componentTheme?: TButtonTheme
  fullWidth?: boolean
  disabled?: boolean
  height?: string
}

export const ExternalLinkButton = memo(
  ({
    href,
    // do not add "_blank" target by default to non-http reference like "mailto:" to escape dummy page to blink
    // (it's opened and immediately closed)
    target = href && /^(http:|https:)/.test(href) ? '_blank' : undefined,
    onClick,
    ...props
  }: Parameters<typeof Wrapper>[0] & Props) => {
    const onLinkClick = (event: SyntheticEvent) => {
      event.stopPropagation()

      const buttonLabel = getChildrenText(props.children)

      onClick && onClick()
      if (href === config.externalLinks.blickSignUpUrl) {
        trackNewsNotifyLinkClick()
      } else {
        trackExternalLinkClick(buttonLabel, href)
      }
    }

    return <Wrapper {...{ href, target, ...props }} onClick={onLinkClick} />
  },
)

ExternalLinkButton.displayName = 'ExternalLinkButton'

const Wrapper = styled.a<Props>`
  text-decoration: none;
  &:hover {
    color: #fff;
  }
  ${buttonStyles}
  ${({ fullWidth }) => fullWidth && 'width: 100%;'}
  ${({ componentTheme }) => buttonTheme[componentTheme || 'primary']}
  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      opacity: 0.5;
    `}
  ${({ height }) => height && `height: ${height}`};
`
