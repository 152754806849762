import { store } from 'src/redux/store'
import {
  TAudioClickEvent,
  TTrackingPayloadAudioClick,
  TTrackingPayload,
  TTrackingPayloadLogin,
  TSlotMachineTrackingPayload,
  TSlotMachinePosition,
  TTrackingPayloadSocialShareClick,
  TTrackingPayloadOutboundLink,
  TTrackingPayloadDashboardClick,
  TTrackingPayloadRulesClick,
  TTrackingPayloadFeedbackClick,
} from './types'
import { getIsLoggedIn, getUserSub } from 'src/api/auth/selectors'
import { LoginMethods } from 'src/enums/auth'
import { ShareType } from 'src/components/buttons/share/enums'
import { config } from 'src/config'
import { getCookie } from 'src/utils/cookie'

const pushToDataLayer = (payload: AnyObject) => {
  window.dataLayer?.push(payload)
}

const pushToDataLayerCache = (payload: AnyObject) => {
  if (!window.dataLayerCache) {
    window.dataLayerCache = []
  }

  window.dataLayerCache?.push(payload)
}

const addAllCachedEvents = () => {
  const cmpCookie = getCookie('OptanonAlertBoxClosed')
  if (!cmpCookie) {
    return
  }

  if (!window.dataLayerCache) {
    return
  }

  window.dataLayerCache.forEach((event) => pushToDataLayer(event))

  delete window.dataLayerCache
}

const trackUserAction = (event: string, payload: AnyObject) => {
  const blickId = getUserSub(store.getState())
  const isLoggedIn = getIsLoggedIn(store.getState())
  const cmpCookie = getCookie('OptanonAlertBoxClosed')

  const mandatoryPayload: TTrackingPayload = {
    page: `${window.location.origin}${window.location.pathname}`,
    blickId,
    is_logged_in: isLoggedIn,
  }
  !config.isProd && console.log({ event, ...mandatoryPayload, ...payload })

  if (cmpCookie) {
    pushToDataLayer({ event, requestSource: 'web', ...mandatoryPayload })
  } else {
    pushToDataLayerCache({ event, requestSource: 'web', ...mandatoryPayload })
  }
}

export const waitForConsentChange = () => {
  // wait for OneTrust to appear in window object
  let counter = 0
  const maxCheck = 100
  const interval = setInterval(() => {
    if (window.OneTrust) {
      window.OneTrust?.OnConsentChanged(addAllCachedEvents)
      clearInterval(interval)
    }

    if (counter >= maxCheck) {
      clearInterval(interval)
    }

    counter++
  }, 500)
}

export const trackSlotMachineImpression = (audioMute: boolean, notificationEnabled?: boolean) => {
  const payload: TSlotMachineTrackingPayload = {
    audio_mute: audioMute,
    notification_enabled: !!notificationEnabled,
  }

  trackUserAction('slot_machine_impression', payload)
}

export const trackSlotMachinePlay = (
  position: TSlotMachinePosition,
  buttonLabel: string,
  audioMute: boolean,
  notificationEnabled?: boolean,
) => {
  const payload: TSlotMachineTrackingPayload = {
    position,
    button_label: buttonLabel,
    audio_mute: audioMute,
    notification_enabled: !!notificationEnabled,
  }

  trackUserAction('slot_machine_play', payload)
}

export const trackSlotMachineNotification = (buttonLabel: string, audioMute: boolean, notificationEnabled: boolean) => {
  const payload: TSlotMachineTrackingPayload = {
    button_label: buttonLabel,
    audio_mute: audioMute,
    notification_enabled: notificationEnabled,
  }

  trackUserAction('slot_machine_notification', payload)
}

export const trackUserLogin = (loginMethod: LoginMethods) => {
  const payload: TTrackingPayloadLogin = {
    login_method: loginMethod,
  }

  trackUserAction('user_login', payload)
}

export const trackAudioClick = (actionLabel: TAudioClickEvent) => {
  const payload: TTrackingPayloadAudioClick = {
    audio_click: actionLabel,
  }

  trackUserAction('audio', payload)
}

export const trackSocialShareImpression = () => {
  trackUserAction('social_share_impression', {})
}

export const trackSocialShareClick = (buttonLabel: ShareType, targetUrl: string) => {
  const payload: TTrackingPayloadSocialShareClick = {
    button_label: buttonLabel,
    target_url: targetUrl,
  }

  trackUserAction('social_share_click', payload)
}

export const trackExternalLinkClick = (buttonLabel: string, targetUrl: string) => {
  const payload: TTrackingPayloadOutboundLink = {
    button_label: buttonLabel,
    target_url: targetUrl,
  }

  trackUserAction('outbound_link', payload)
}

export const trackNewsNotifyLinkClick = () => {
  trackUserAction('newsletter_notification_click', {})
}

export const trackUserDashboardOpen = () => {
  trackUserAction('dashboard_open', {})
}

export const trackUserDashboardClick = (buttonLabel: string, targetUrl: string) => {
  const payload: TTrackingPayloadDashboardClick = {
    button_label: buttonLabel,
    target_url: targetUrl,
  }

  trackUserAction('dashboard_click', payload)
}

export const trackRulesClick = (buttonLabel: string) => {
  const payload: TTrackingPayloadRulesClick = {
    button_label: buttonLabel,
  }

  trackUserAction('spielregel', payload)
}

export const trackFeedbackImpression = () => {
  trackUserAction('feedback_impression', {})
}

export const trackFeedbackClick = (buttonLabel: string) => {
  const payload: TTrackingPayloadFeedbackClick = {
    button_label: buttonLabel,
  }

  trackUserAction('feedback_click', payload)
}
