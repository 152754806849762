import styled from 'styled-components/macro'

import { pixelify } from 'src/utils/style/pixelify'

type Props = {
  width?: string | number
  height?: string | number
}

export const BaseElement = styled.div<Props>`
  min-height: 6px;
  min-width: 6px;
  ${({ height }) => height && `height: ${pixelify(height)}`};
  ${({ width }) => width && `width: ${pixelify(width)}`};
`
