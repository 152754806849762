import { Image } from 'src/components/Image'
import { Slider, TSliderSettings } from 'src/components/Slider'
import { Autoplay, Pagination, Navigation } from 'swiper'
import { ImageWrapper } from 'src/containers/Prizes/Prize/Prize'

type Props = {
  imageUrls: string[]
}

export const SponsoringSlider = ({ imageUrls }: Props) => {
  const multipleImages = Boolean(imageUrls.length > 1)
  const sliderSettings: TSliderSettings = {
    modules: [Autoplay, Pagination, Navigation],
    slidesPerView: 1,
    autoHeight: true,
    loop: multipleImages,
    autoplay: multipleImages
      ? {
          delay: 3000,
        }
      : false,
    pagination: multipleImages
      ? {
          clickable: true,
        }
      : false,
    navigation: multipleImages,
  }
  return (
    <Slider settings={sliderSettings}>
      {imageUrls.map((image, key) => (
        <ImageWrapper key={key}>
          <Image src={image} />
        </ImageWrapper>
      ))}
    </Slider>
  )
}
