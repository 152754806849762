const ANIMATION_BASE_CLASSNAME = 'drawer'

export const ANIMATION_DURATION = 300

export const ANIMATION_CLASSNAMES = {
  ENTER: `.${ANIMATION_BASE_CLASSNAME}-enter`,
  ENTER_ACTIVE: `.${ANIMATION_BASE_CLASSNAME}-enter-active`,
  ENTER_DONE: `.${ANIMATION_BASE_CLASSNAME}-enter-done`,
  EXIT: `.${ANIMATION_BASE_CLASSNAME}-exit`,
  EXIT_ACTIVE: `.${ANIMATION_BASE_CLASSNAME}-exit-active`,
  EXIT_DONE: `.${ANIMATION_BASE_CLASSNAME}-exit-done`,
}

export const DRAWER_WIDTH = 407
export const DRAWER_MOBILE_TOP_OFFSET = 24

export const DRAWER_INDEX = {
  USER_DASHBOARD: 'user-dashboard',
}
