import styled from 'styled-components/macro'
import { useSelector, useDispatch } from 'react-redux'

import { logout } from 'src/api/auth/actions'
import { getUserNickName } from 'src/api/auth/selectors'
import { firstLetter } from 'src/utils/other'
import { config } from 'src/config'

import { ActionButton, UserIcon } from 'src/containers/UserDashboard/components'
import { ReactComponent as LogoutIcon } from 'src/assets/images/file_upload_icon.svg'
import { trackUserDashboardClick } from 'src/tracking/gtm/actions'

export const UserDashBoardFooter = () => {
  const dispatch = useDispatch()

  const userNickName = useSelector(getUserNickName)

  const initials = `${firstLetter(userNickName)}` || '-'

  const onClickProfile = (buttonLabel: string) => {
    const url = config.api.onelog.profileUrl
    trackUserDashboardClick(buttonLabel, url)
    window.location.href = url
  }

  const onClickLogout = (buttonLabel: string) => {
    trackUserDashboardClick(buttonLabel, '/logout')
    dispatch(logout({ returnUrl: window.location.href }))
  }

  return (
    <FooterWrap>
      <ActionButton onClick={() => onClickProfile('Mein Konto')}>
        <UserIcon>{initials}</UserIcon>
        <span>Mein Konto</span>
      </ActionButton>
      <ActionButton onClick={() => onClickLogout('Abmelden')}>
        <LogoutIcon />
        <span>Abmelden</span>
      </ActionButton>
    </FooterWrap>
  )
}

const FooterWrap = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  padding: 16px 16px;
  background-color: ${({ theme }) => theme.colors.blick.grey10};
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.12);
  ${ActionButton} + ${ActionButton} {
    margin-left: 32px;
  }
`
