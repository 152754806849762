import { Middleware } from 'redux'
import { ActionTypes as AppActionTypes } from 'src/state/app/enums'
import { trackAudioClick } from './actions'
export const trackingMiddleware: Middleware = (store) => (next) => (action) => {
  if (action) {
    switch (action.type) {
      case AppActionTypes.SET_IS_AUDIO_ENABLED: {
        trackAudioClick(action.payload ? 'unmute' : 'mute')
        break
      }
    }

    return next(action)
  }
}
