import { isEmpty } from 'lodash-es'

import { TReduxState } from 'src/redux/store'

export const getIsLoggedIn = (state: TReduxState): boolean => state.auth.loggedIn

export const getIsAuthResolved = (state: TReduxState): boolean => state.auth.authResolved
export const getAuthLoading = (state: TReduxState): boolean => state.auth.isLoading
export const getUserNickName = (state: TReduxState) => state.auth.user?.nickname
export const getUserEmail = (state: TReduxState) => state.auth.user?.email
export const getUserHasMarketingOptin = (state: TReduxState) => state.auth.user?.hasMarketingOptin

export const getIsAgreementsSigned = (state: TReduxState): boolean => !!state.auth.user?.gamePlatformAgreementSigned
export const getHasUserNickname = (state: TReduxState) => {
  const nickname = getUserNickName(state)
  return !isEmpty(nickname)
}
export const getHasUserFullAddress = (state: TReduxState): boolean => !!state.auth.user?.hasFullAddress
export const getUserSub = (state: TReduxState) => state.auth.metaData?.sub
