const sectionPaddings = {
  sides: '16px',
  top: '40px',
  bottom: '48px',
}

export const measures = {
  contentWidth: '954px',
  contentWidthNarrow: '653px',
  navigation: {
    height: '60px',
    heightMobile: '48px',
    miniHeaderHeight: '58px',
    headerHeightDesktop: '110px',
    headerHeightMobile: '76px',
    navigationScrollThreshold: '50px',
  },
  section: {
    paddingHorizontal: sectionPaddings.sides,
    paddingTop: sectionPaddings.top,
    paddingBottom: sectionPaddings.bottom,
    padding: `${sectionPaddings.top} ${sectionPaddings.sides} ${sectionPaddings.bottom} ${sectionPaddings.sides}`,
  },
}
