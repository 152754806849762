import styled from 'styled-components/macro'

type Props = React.HTMLAttributes<HTMLButtonElement> & {
  width: number
  height: number
  icon: React.ReactElement
}

const BUTTON_DEFAULT_PADDING = 4

const IconButtonWrapper = styled.button<Omit<Props, 'icon'>>`
  padding: ${BUTTON_DEFAULT_PADDING}px;
  width: ${({ width }) => width + BUTTON_DEFAULT_PADDING * 2 + 'px'};
  min-width: ${({ width }) => width + BUTTON_DEFAULT_PADDING * 2 + 'px'};
  height: ${({ height }) => height + BUTTON_DEFAULT_PADDING * 2 + 'px'};
  min-height: ${({ height }) => height + BUTTON_DEFAULT_PADDING * 2 + 'px'};
  background-color: transparent;
  border: none;
  cursor: pointer;
  transition: opacity 0.15s ease;
  &:active {
    opacity: 0.5;
  }

  svg,
  img {
    width: 100%;
    height: 100%;
  }
`

export const IconButton = ({ icon, ...props }: Props) => {
  return <IconButtonWrapper {...props}>{icon}</IconButtonWrapper>
}
