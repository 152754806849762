import React from 'react'
import { toast, ToastOptions } from 'react-toastify'
import { CloseButtonText } from './CloseIconText'
import { WarningIcon, SuccessIcon } from './toastIcons'
import { TOAST_CLASSNAMES } from './constants'

const defaultToastOptions: ToastOptions = {
  closeButton: true,
  icon: true,
}

export const toasty = (content: string | React.ReactElement, options?: ToastOptions, toastId?: string) => {
  if (options && options.type !== 'success' && options.type !== 'error' && options.type !== 'warning') {
    options.icon = false
  } else if (options?.type === 'warning') {
    options.icon = WarningIcon()
  } else if (options?.type === 'success') {
    options.icon = SuccessIcon()
  } else if (!options) {
    defaultToastOptions.icon = false
  }
  if (options?.autoClose === false) {
    options.closeButton = CloseButtonText()
  }
  let toastOptions
  let toastClassName = TOAST_CLASSNAMES[options?.type || 'default']

  if (options) {
    const { className, ...restOptions } = options
    toastClassName = `${toastClassName} ${className ? className : ''}`
    toastOptions = restOptions
  }

  toast(content, {
    className: toastClassName,
    toastId,
    ...defaultToastOptions,
    ...toastOptions,
  })
}

export const onCloseToast = (toastId: string | number) => toast.dismiss(toastId)
