import styled from 'styled-components/macro'
import { Container } from 'src/components/Container'

import { Image } from 'src/components/Image'
import { Spacer } from 'src/components/Spacer'

import { useSelector } from 'react-redux'
import { getCurrentCampaignRules } from 'src/api/product/selectors'

import { ExternalLink } from 'src/components/links/ExternalLink'
import { SectionTitle } from 'src/components/typography/SectionTitle'
import { Markdown } from 'src/components/Markdown'
import { Rule } from './Rule'

import rulesIcon from 'src/assets/images/info_icon.svg'
import { trackRulesClick } from 'src/tracking/gtm/actions'

export const Rules = () => {
  const rules = useSelector(getCurrentCampaignRules)

  return (
    <StyledContainer>
      <SectionIcon src={rulesIcon} alt="rules" />
      <Spacer size={12} v />
      <SectionTitle>Spielregeln</SectionTitle>
      <Spacer size={16} v />
      <SectionDescription>
        Hier findest du die{' '}
        <ExternalLink
          href="https://www.blick.ch/gewinnen/das-muessen-sie-wissen-teilnahmebedingungen-id7732110.html"
          target="_blank"
        >
          Teilnahmebedingungen
        </ExternalLink>
        . Viel Spass und Erfolg!
      </SectionDescription>
      <Spacer size={24} v />
      {rules && (
        <div>
          {rules.map((rule) => (
            <Rule title={rule.title} key={rule.title} onClick={() => trackRulesClick(rule.title)} closedByDefault>
              <Markdown>{rule.content}</Markdown>
            </Rule>
          ))}
        </div>
      )}
    </StyledContainer>
  )
}

const StyledContainer = styled(Container)`
  text-align: center;
  padding-bottom: 80px;
`

const SectionIcon = styled(Image)`
  width: 50px;
  height: 50px;
`

const SectionDescription = styled.span`
  font-weight: 600;
  font-size: 22px;
  line-height: 25px;
`
