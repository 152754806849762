import styled from 'styled-components/macro'

type Props = {
  href?: string
  children?: string
  onClick?: () => void
}

export const FooterLink = (props: Props) => (
  <Wrapper>
    <StyledLink {...props} />
  </Wrapper>
)

const StyledLink = styled.a`
  color: ${({ theme }) => theme.colors.blick.brand100};
  text-decoration: none;
  font-family: ${({ theme }) => theme.fonts.sourceSansPro};
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  cursor: pointer;
`

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 1;

  height: 56px;
`
