import styled, { css } from 'styled-components/macro'

type TCloseIconColor = 'black' | 'red'

type Props = {
  size?: number
  color?: TCloseIconColor
}

export const CloseIcon = styled.div<Props>`
  position: relative;
  z-index: 1;

  ${({ size }) => css`
    width: ${size || 32}px;
    height: ${size || 32}px;
  `}

  &:hover {
    opacity: 1;
  }

  &:before,
  &:after {
    position: absolute;
    left: ${({ size }) => (size ? size / 2 - 1 : 15)}px;
    content: ' ';
    height: ${({ size }) => (size ? size + 1 : 33)}px;
    width: 2px;
    background-color: ${({ color, theme }) => {
      if (color === 'red') {
        return theme.colors.blick.red
      }
      return theme.colors.blick.brand100
    }};
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
`
