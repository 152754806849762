import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import relativeTime from 'dayjs/plugin/relativeTime'
import timezone from 'dayjs/plugin/timezone'
import duration from 'dayjs/plugin/duration'
import 'dayjs/locale/de'

// dayjs init
dayjs.extend(utc)
dayjs.extend(relativeTime)
dayjs.extend(timezone)
dayjs.extend(duration)
dayjs.tz.setDefault('CET')
dayjs.locale('de')

// Debug log the client's timezone
// console.log('> timezone', dayjs.tz.guess())
