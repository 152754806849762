import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getProduct } from 'src/api/product/selectors'

import { fetchProduct } from 'src/api/product/actions'
import { TRouteParams } from 'src/router/types'
import { ProductContainer } from 'src/containers/Product/Product'
import { NoGameProductContainer } from 'src/containers/Product/NoGameProduct'
import { getIsAudioEnabled } from 'src/state/app/selectors'
import { getIsAuthResolved, getUserHasMarketingOptin } from 'src/api/auth/selectors'
import { trackSlotMachineImpression } from 'src/tracking/gtm/actions'
import { isInThePast } from 'src/utils/date/relativeDate'

export const Product = () => {
  const dispatch = useDispatch()
  const { productId } = useParams<TRouteParams>()

  const productData = useSelector(getProduct)
  const isAuthResolved = useSelector(getIsAuthResolved)
  const isAudioEnabled = useSelector(getIsAudioEnabled)
  const userHasMarketingOptin = useSelector(getUserHasMarketingOptin)
  const isCurrentCanpaignInThePast = productData?.currentCampaign ? isInThePast(productData?.currentCampaign) : null

  useEffect(() => {
    if (isAuthResolved) {
      trackSlotMachineImpression(!isAudioEnabled, userHasMarketingOptin)
    }
  }, [isAuthResolved, isAudioEnabled, userHasMarketingOptin])

  useEffect(() => {
    if (productId) {
      dispatch(fetchProduct({ productId }))
    }
  }, [productId, dispatch])

  return (
    <>
      {productId && productData?.currentCampaign && isCurrentCanpaignInThePast === false ? (
        <ProductContainer productId={productId} productData={productData} />
      ) : (
        <NoGameProductContainer productId={productId} />
      )}
    </>
  )
}
